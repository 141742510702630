import React, { forwardRef } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikProps } from 'formik';
import * as yup from 'yup';

// Définir le type pour les données du formulaire
export type ScenarioProps = 'scenario 1' | 'scenario 2' | 'scenarioPerso' | '';

export type MailMarFormData = {
    scenario: ScenarioProps;
    scenarioPersoText?: string;
    comment: string | undefined;
};

export type MailMarFormProps = {
    initialValues: MailMarFormData;
    onSubmit: (values: MailMarFormData) => void;
    onClose: () => void;
};

export const MailMarFormSchema = yup.object().shape({
    scenario: yup.string().required(),
    scenarioPersoText: yup.string().when('scenario', {
        is: 'scenarioPerso',
        then: yup.string().required('Veuillez saisir un texte pour le scénario personnalisé.'),
    }),
    comment: yup.string(),
});

export const DEFAULT_COMMENT_FOR_MAR_1 = 'Le client a retenu le scénario 1';
export const DEFAULT_COMMENT_FOR_MAR_2 = 'Le client a retenu le scénario 2';
export const DEFAULT_COMMENT_FOR_MAR_PERSO = 'Le client a retenu un scénario personnalisé (merci de compléter) : ';

const MailMarForm = forwardRef<FormikProps<MailMarFormData>, MailMarFormProps>((props: MailMarFormProps, ref) => {
    const scenarios = [
        {
            value: 'scenario1',
            label: DEFAULT_COMMENT_FOR_MAR_1,
            defaultComment: DEFAULT_COMMENT_FOR_MAR_1,
        },
        {
            value: 'scenario2',
            label: DEFAULT_COMMENT_FOR_MAR_2,
            defaultComment: DEFAULT_COMMENT_FOR_MAR_2,
        },
        {
            value: 'scenarioPerso',
            label: DEFAULT_COMMENT_FOR_MAR_PERSO,
            defaultComment: DEFAULT_COMMENT_FOR_MAR_PERSO,
        },
    ];

    return (
        <div>
            <p>Sélectionnez un commentaire à destination de l'accompagnateur Rénov'</p>
            <Formik
                initialValues={props.initialValues}
                validationSchema={MailMarFormSchema}
                onSubmit={props.onSubmit}
                innerRef={ref}
                enableReinitialize
                validateOnMount
                validateOnChange
            >
                {({ values, setFieldValue, errors }) => (
                    <Form className="container">
                        <div className="row">
                            {scenarios.map((scenario, index) => (
                                <div className="col-12" key={index}>
                                    <div className="form-group">
                                        <div className="custom-radio">
                                            <Field
                                                type="radio"
                                                name="scenario"
                                                id={scenario.value}
                                                value={scenario.value}
                                                onChange={() => {
                                                    setFieldValue('scenario', scenario.value);
                                                }}
                                            />
                                            <div className="fake-radio">{/* Leave empty */}</div>
                                            <label htmlFor={scenario.value}>{scenario.label}</label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <p className="text-start mt-4">Veuillez saisir les élements que le client souhaiterait installer : </p>
                        <div className="position-relative">
                            <Field
                                as="textarea"
                                name="scenarioPersoText"
                                placeholder="Entrez votre scénario personnalisé"
                                disabled={values.scenario !== 'scenarioPerso'}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setFieldValue('scenarioPersoText', e.target.value);
                                    setFieldValue('comment', DEFAULT_COMMENT_FOR_MAR_PERSO + e.target.value); // Met à jour également "comment"
                                }}
                                className="form-control"
                            />

                            <ErrorMessage name="scenarioPersoText" component="span" className="invalid-feedback " />
                        </div>
                        <div className="btn-grp justify-content-center mt-5">
                            <button type="button" className="btn btn-retour min-width" onClick={props.onClose}>
                                Annuler
                            </button>
                            <button type="submit" className="btn btn-continue min-width" disabled={Object.keys(errors).length > 0}>
                                Envoyer
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
});
export default MailMarForm;
