import React from 'react';
import * as storageService from '../../services/localStorageService';

// Styles
import './recap.scss';

// Icons
import Cookies from 'js-cookie';

export const RecapIneligible: React.FC = () => {
    const companyName = Cookies.getJSON('Auth').companyName;

    return (
        <>
            {storageService.getAudit().step6?.exteriorWallInsulationAge?.value !== '' && (
                <div className="container" id="not-eligible">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7">
                            <h1 className="main-title-mini">Votre dossier n'est pas éligible au programme Action Logement</h1>
                            <p>
                                Les informations que vous avez transmis dans le cadre de votre Audit Energétique ne vous permettent malheureusement pas de
                                bénéficier du programme Action Logement.
                            </p>
                            <p>
                                Cependant une multitude d'autre aide sont à votre disposition. Notre service client ne manquera pas de prendre contact avec vous
                                dans les plus bref délai afin d'évaluer avec vous toutes les dispositions auxquelles vous auriez accès.
                            </p>
                            <p>L'équipe {companyName}</p>
                        </div>
                    </div>
                </div>
            )}

            {/* Inéligible et pas d'isolation */}
            {storageService.getAudit().step6?.exteriorWallInsulationAge?.value === '' && (
                <div className="container" id="not-eligible">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7">
                            <h1 className="main-title-mini">Votre dossier est eligible au programme action logement</h1>
                            <p>
                                Les informations que vous avez transmis dans le cadre de votre Audit Energétique indique que les murs de votre maison ne sont
                                pas isolé. Ni par l'intérieur et ni par l'éxtérieur.
                            </p>
                            <p>
                                Cela nécessite une étude plus approfondie et devons déplacer un technicien à votre domicile afin de réaliser une pré visite
                                technique. Notre service technique ne manquera pas de prendre contact avec vous dans les jours à venir afin de convenir d'une
                                date.
                            </p>
                            <p>L'équipe {companyName}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
