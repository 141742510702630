import { ApiDocument } from './apiFlowService';
import * as api from './apiParticulierService';
import { ClientIdentity } from './localStorageService';

export type CodeLibelle = { code: string; libelle?: string };

// READ ME
//
// Voici ce que je suppose être le process à utiliser :
// 1) rechercher le code insee. (getCodeInsee)
// 2) rechercher le PRM  (recherchePoint)
// 3) demander l'accès (commanderAccesDonneesMesures)
// 4) obtenir les informations contractuelles (consulterDonneesTechniquesContractuelles)
// 5) lire les mesure  (consulterMesures, consulterMesuresDetailleesV3)

//#region Recherche de point

// 2.36
export const domainTensionCodeNames = ['BTINF', 'BTSUP', 'HTA', 'HTB'] as const;
export type DomaineTension =
    | { code: 'BTINF'; libelle: 'BT<=36kVA' }
    | { code: 'BTSUP'; libelle: 'BT>36kVA' }
    | { code: 'HTA'; libelle: 'HTA' }
    | { code: 'HTB'; libelle: 'HTB' };

// 2.16
export const clientFinalCategorieCodeNames = ['PRO', 'RES'] as const;
export type ClientFinalCategorie = { code: 'PRO'; libelle: 'Professionnel' } | { code: 'RES'; libelle: 'Résidentiel' };

export type AdresseInstallation = {
    escalierEtEtageEtAppartement?: string;
    batiment?: string;
    numeroEtNomVoie?: string;
    lieuDit?: string;
    codePostal?: string;
    /** use getCodeInsee to get it ! */
    codeInseeCommune?: string;
};
/**
 * exemple : `{
 *   "adresseInstallation": {
 *       "numeroEtNomVoie": "29 rue nast",
 *       "codePostal": "77420",
 *       "codeInseeCommune": "77083"
 *   },
 *  "categorieClientFinalCode": "RES"
 * }`
 * exemple : `{
 *     "numSiret":"41228073720375",
 *     "categorieClientFinalCode": "PRO"
 * }`
 */
export type Criteres = {
    numSiret?: string;
    matriculeOuNumeroSerie?: string;
    domaineTensionAlimentationCode?: DomaineTension['code'];
    nomClientFinalOuDenominationSociale?: string;
    categorieClientFinalCode?: ClientFinalCategorie['code'];
    rechercheHorsPerimetre?: boolean;
    adresseInstallation?: AdresseInstallation;
};
/** Point, ou PRM ou autrement dit le linky, le point de raccordement ... avec son id */
export type Point = {
    /** Numéro de PRM == pointId */
    id: string;
    adresseInstallationNormalisee: AdresseInstallationNormalisee;
    etatContractuel: CodeLibelle;
    matricule?: string;
    numeroSerie?: string;
    typeComptage?: CodeLibelle;
    nomClientFinalOuDenominationSociale?: string;
};

export type AdresseInstallationNormalisee = {
    ligne1?: string;
    ligne2?: string;
    ligne3?: string;
    ligne4?: string;
    ligne5?: string;
    ligne6?: string;
    ligne7?: string;
};

/**
 * Rechercher un(des) numéro(s) de PRM, selon des critères.
 * Les combinaisons de recherches possibles sont les suivantes :
 *    -  couple code postal et code INSEE + autre(s) critère(s) complémentaire(s) ;
 *       Note : dans ce cas le complément d’adresse ne peut pas être utilisé comme critère complémentaire seul. Il
 *       doit être combiné avec un autre critère ;
 *    -  n° SIRET uniquement ;
 *    -  n° SIRET + autre(s) critère(s) complémentaire(s).
 *
 * @param input les critères de recherche.
 * @returns LES (il peut y en avoir plusieurs) point trouvés.
 *
 *
 */
export const recherchePoint = async (input: Criteres): Promise<Array<Point>> => {
    return await api.postJsonData('enedis/sge/recherche-point', input);
};

//#endregion

//#region demander l'accès aux données :

export const mesureTypeInputNames = [
    /** pour les énergies globales quotidiennes, */
    'ENERGIE',
    /** pour les puissances maximales quotidiennes ou mensuelles, */
    'PMAX',
    /** pour une courbe (de puissance ou de tension), */
    'COURBE',
    /** pour les index quotidiens. */
    'INDEX',
    /** pour les index quotidiens. */
    'IDX',

    'CDC',
] as const;
export type MesureTypeInput = (typeof mesureTypeInputNames)[number];

export type AccordClient = {
    accord: boolean;
    /** mutually exclusive with personneMorale */
    personnePhysique?: {
        civilite?: 'M' | 'Mme' | 'Mlle';
        nom: string;
        prenom?: string;
    };
    /** mutually exclusive with PersonnePhysique */
    personneMorale?: {
        denominationSociale: string;
    };
};

export type CommanderAccesDonneesMesuresInput = {
    sogysId: string;
    pointId: string;
    accesDonnees: {
        dateDebut: string;
        dateFin?: string;
        declarationAccordClient: AccordClient;
        typeDonnees: MesureTypeInput;
        soutirage?: boolean;
        injection?: boolean;
    };
};

export type CommanderAccesDonneesMesuresOutput = {
    affaireId: string;
    prestations?: Array<Prestation>;
    serviceSouscritId: string;
};

export type Prestation = {
    rang: number;
    fiche: CodeLibelle;
    option?: string;
    cas?: string;
};

export const commanderAccesDonneesMesures = async (input: CommanderAccesDonneesMesuresInput): Promise<CommanderAccesDonneesMesuresOutput> => {
    return await api.postJsonData('enedis/sge/demande-acces-donnees-mesures', input);
};

//#endregion

//#region info techniques contractuelles

export type ConsultationDonneesTechniquesContractuellesInput = {
    pointId: string;
    //autorisationClient?: boolean; // géré par l'api
};

export type PointTechniqueContractuel = {
    id: string;
    donneesGenerales: DonneesGenerales;
    situationAlimentation: SituationAlimentation;
    situationComptage: SituationComptage;
    situationContractuelle?: SituationContractuelle;
};

export type DonneesGenerales = {
    etatContractuel: CodeLibelle;
    adresseInstallation: AdresseInstallation;
    dateDerniereModificationFormuleTarifaireAcheminement?: string;
    dateDerniereAugmentationPuissanceSouscrite?: string;
    dateDerniereDiminutionPuissanceSouscrite?: string;
    segment: CodeLibelle;
    niveauOuvertureServices?: string; // ne correspond aps à la doc.
    //niveauOuvertureServices?:CodeLibelle;
};
export type SituationAlimentation = {
    alimentationPrincipale: AlimentationPrincipale;
};
export type AlimentationPrincipale = {
    domaineTension?: CodeLibelle;
    tensionLivraison?: CodeLibelle;
    modeApresCompteur?: CodeLibelle;
    puissanceRaccordementSoutirage?: Puissance;
};
export type SituationComptage = {
    dispositifComptage?: DispositifComptage;
    caracteristiquesReleve?: CaracteristiquesReleve;
    modeReleve?: CodeLibelle;
    mediaReleve?: CodeLibelle;
    futuresPlagesHeuresCreuses?: CodeLibelle;
    futuresProgrammationsHoraires?: Array<FormuleTarifaireAcheminement>;
};
export type SituationContractuelle = {
    structureTarifaire: StructureTarifaire;
};
export type StructureTarifaire = {
    formuleTarifaireAcheminement: CodeLibelle;
    longueUtilisation?: LongueUtilisation;
    puissanceSouscriteMax: Puissance;
    denivelePuissances?: DenivelePuissances;
    calendrierFrn?: CodeLibelle;
};

export type LongueUtilisation = {
    contexte?: CodeLibelle;
    forfait?: CodeLibelle;
};
export type DenivelePuissances = {
    classesTemporelles: Array<ClasseTemporelle>;
};
export type ClasseTemporelle = CodeLibelle & {
    puissance: Puissance;
};
export type Puissance = {
    valeur: string;
    unite: CodeLibelle;
};

export type DispositifComptage = {
    typeComptage: CodeLibelle;
    compteurs?: Array<Compteur>;
    disjoncteur?: Disjoncteur;
    relais?: Relais;
    transformateurCourant?: TransformateurCourant;
    transformateurTension?: TransformateurTension;
};
export type CaracteristiquesReleve = {
    modeTraitement?: CodeLibelle;
    periodicite?: CodeLibelle;
    plageReleve?: CodeLibelle;
};
export type FormuleTarifaireAcheminement = CodeLibelle & {
    programmationHoraire?: ProgrammationHoraire;
};
export type FuturesProgrammationsHoraires = {
    programmationHoraire?: ProgrammationHoraire;
};
export type ProgrammationHoraire = {
    programmationPosteHoraire: CodeLibelle & {
        periodesHoraires?: string;
    };
};

export type Compteur = {
    localisation?: CodeLibelle;
    matricule?: string;
    ticActivee?: boolean;
    ticStandard?: boolean;
    ticActivable?: boolean;
    plagesHeuresCreuses?: string;
    parametresTeleAcces?: ParametresTeleAcces;
    programmationHoraire?: ProgrammationHoraire;
};
export type Disjoncteur = {
    calibre?: CodeLibelle;
};
export type Relais = {
    plageHeuresCreuses?: string;
};
export type TransformateurCourant = {
    calibre?: CodeLibelle;
    couplage?: CodeLibelle;
    classePrecision?: CodeLibelle;
    position?: CodeLibelle;
};
export type TransformateurTension = {
    calibre?: CodeLibelle;
    couplage?: CodeLibelle;
    classePrecision?: CodeLibelle;
};

export type ParametresTeleAcces = {
    numeroTelephone?: string;
    numeroVoieAiguillage?: string;
    etatLigneTelephonique?: 'Partagée' | 'Dédiée';
    fenetreEcouteClient?: FenetreEcouteClient;
    cle?: string;
};

export type FenetreEcouteClient = {
    heureDebut?: string;
    duree?: CodeLibelle;
};

export const consulterDonneesTechniquesContractuelles = async (input: ConsultationDonneesTechniquesContractuellesInput): Promise<PointTechniqueContractuel> => {
    return await api.postJsonData('enedis/sge/donnees-techniques-contractuelles', input);
};
//#endregion

//#region consulter Mesure

export const MAX_CONSULTER_MESURE_HISTORY_MONTH = 36;

export type ConsulterMesuresInput = {
    pointId: string;
    // autorisationClient?: boolean; // géré par l'api
    /** Ne pas utiliser a priori */
    contratConcluNouveauClientSurSite?: boolean;
};

export type ConsultationMesuresOutput = Array<Serie>;

export type Serie = {
    grandeurPhysique: { code: 'EA'; libelle: 'Energie Active' }; //uniquement
    /** libelle non restitué dans la réponse, a reconstruire lors du parse */
    classeTemporelle: CodeLibelle;
    /** libelle non restitué dans la réponse, a reconstruire lors du parse.
     * C5 uniquement
     */
    calendrier?: CodeLibelle;
    unite: 'kWh';
    mesuresDatees: Array<MesureDatee>;
};

export type MesureDatee = {
    valeur: number;
    dateDebut: string;
    dateFin: string;
    nature: CodeLibelle;
    declencheur: CodeLibelle;
    statut: CodeLibelle;
};

export const consulterMesures = async (input: ConsulterMesuresInput): Promise<ConsultationMesuresOutput> => {
    return await api.postJsonData('enedis/sge/mesure', input);
};

//#endregion

//#region consulter mesures detaillees

export const MAX_CONSULTER_MESURE_COURBE_HISTORY_MONTH = 24;
export const MAX_CONSULTER_MESURE_PMAX_HISTORY_MONTH = 36;

export const mesuresPasNames = ['P1D', 'P1M', undefined] as const;
export const mesuresPasExtNames = [...mesuresPasNames, 'P7D', 'P14D', 'P1Y'] as const;
export type MesuresPas = (typeof mesuresPasNames)[number];
export type MesuresPasExt = (typeof mesuresPasExtNames)[number];

export const cadreAccesV3InputName = ['ACCORD_CLIENT', 'SERVICE_ACCES', 'EST_TITULAIRE'] as const;
export type CadreAccesV3Input = (typeof cadreAccesV3InputName)[number];

export const mesureTypeInputNamesForMDV3 = [
    'ENERGIE', // pour les énergies globales quotidiennes,
    'PMAX', // pour les puissances maximales quotidiennes ou mensuelles,
    'COURBE', // pour une courbe (de puissance ou de tension),
    'INDEX', // pour les index quotidiens.
] as const;
export type MesureTypeInputForMDV3 = (typeof mesureTypeInputNames)[number];

export const GrandeurPhysiqueCourbeInputNames = ['PA', 'PRI', 'PRC', 'E', 'TOUT'] as const;
export const GrandeurPhysiqueIndexInputNames = ['EA', 'ER', 'ERC', 'ERI', 'DD', 'DE', 'DQ', 'PMAX', 'TF', 'TOUT'] as const;
export const GrandeurPhysiqueInputNames = ['PMA', 'EA', ...GrandeurPhysiqueCourbeInputNames, ...GrandeurPhysiqueIndexInputNames] as const;

export type GrandeurPhysiqueCourbeInput = (typeof GrandeurPhysiqueCourbeInputNames)[number];
export type GrandeurPhysiqueIndexInput = (typeof GrandeurPhysiqueIndexInputNames)[number];
export type GrandeurPhysiqueInput = (typeof GrandeurPhysiqueInputNames)[number];

export type ConsulterMesuresDetailleesV3Input = {
    pointId: string;
    mesuresTypeCode: MesureTypeInputForMDV3; // pour les index quotidiens.
    grandeurPhysique: GrandeurPhysiqueInput;
    sens: 'INJECTION' | 'SOUTIRAGE';
    dateDebut: string;
    dateFin: string;
    /** Ne pas fournir sauf consultation des puissances max. */
    mesuresPas?: MesuresPas;
    /** Pour le C1-C4 et le P1-P3, indiquer ‘true’ pour des données corrigées, ‘false’ pour des données brutes. pour C5 et PA : false. */
    mesuresCorrigees: boolean;
    /** Seule la consultation de données de PMAX mensuelle est possible avec l’option EST_TITULAIRE. */
    cadreAcces: CadreAccesV3Input;
};
export type Periode = { dateDebut: string; dateFin: string };
export type MesureDetaillee = {
    d: Date;
    v: string;
    iv:
        | 0 // pour Valeur valide
        | 1 // pour Valeur potentiellement sujette à caution suite à contrôle
        | 2; // pour Valeur associée à un état complémentaire
};
export type MesureDetailleeExt = {
    v?: string;
    d: string;
    /** le pas, pour les courbes uniquement */
    p?: string | 'PT5M' | 'PT10M' | 'PT15M' | 'PT30M' | 'PT60M';
    /** la nature, pour les courbes uniquement */
    n?:
        | 'B' // : Brut
        | 'C' // : Corrigé
        | 'R' // : Réel
        | 'H' // : Puissance reconstituée
        | 'P' // : Puissance reconstituée et coupure secteur
        | 'D' // : Importé manuellement par le métier Enedis
        | 'S' // : Coupure Secteur
        | 'T' // : Coupure secteur courte
        | 'F' // : Début de coupure secteur
        | 'G' // : Fin de coupure secteur
        | 'E'; // : Estimé
    /** type de completion, pour les courbes uniquement */
    tc?:
        | 'A' // : Recopie J-7
        | 'L' // : Multiplication point par point avec une autre courbe
        | 'G' // : Remplacement par une constante
        | 'H' // : Recopie d’une période similaire
        | 'N' // : Interpolation linéaire
        | 'K' // : Multiplication par une constante
        | 'F' // : Correction point par point
        | 'J' // : Valeur écrêtée
        | 'D' // : Interpolation linéaire sans bornes
        | 'I' // : Décalage vertical
        | 'O' // : Valeur automatique acceptée sans correction
        | 'M' // : Valeur forcée à 0
        | 'C' // : Interpolation linéaire avec bornes
        | 'B'; // : Recopie A-1
    /** indice de vraissemblance, pour les courbes uniquement */
    iv?:
        | 0 // pour Valeur valide
        | 1 // pour Valeur potentiellement sujette à caution suite à contrôle
        | 2; // pour Valeur associée à un état complémentaire
    /** etat complémentaire, pour les courbes uniquement */
    ec?:
        | 0 // Pas d’état complémentaire
        | 1 // Coupure secteur
        | 5 // Reprise secteur
        | 6 // Correction d'heure
        | 7 // Somme puissances
        | 8 // Compteur non synchronisé
        | 9 // Reprise secteur et compteur non synchronisé
        | 10 // Correction heure et somme puissances
        | 11; // Coupure puis reprise secteur
};
export type CalendrierEntry = {
    idCalendrier: string;
    libelleCalendrier: string;
    classeTemporelles: Array<ClasseTemporelleEntry>;
};

export type ClasseTemporelleEntry = CadranEntry & {
    idClasseTemporelle: string;
    libelleClasseTemporelle: string;
};
export type CodeCadran =
    | 'IDX_EAI_T' // (pour les index Totalisateurs d'Energie Active en Injection)
    | 'IDX_EAS_T' // (pour les index Totalisateurs d'Energie Active en Soutirage)
    | 'IDX_ERI_C' // (pour les index Totalisateurs d'Energie Réactive Capacitive en Injection)
    | 'IDX_ERS_C' // (pour les index Totalisateurs d'Energie Réactive Capacitive en Soutirage)
    | 'IDX_ERI_I' // (pour les index Totalisateurs d'Energie Réactive Inductive en Injection)
    | 'IDX_ERS_I'; // (pour les index Totalisateurs d'Energie Réactive Inductive en Soutirage)

export type CadranEntry = {
    codeCadran: CodeCadran;
    valeurs: Array<MesureDetaillee>;
};
export type GrandeurV3 = {
    /** SI grandeurPhysique === "E" => ne s'appliquer pas.
     *    sens === "INJECTION" || injection === true =>  grandeurMetier: "CONS"
     *    sens === "SOUTIRAGE" || soutirage === true =>  grandeurMetier: "PROD"
     */
    grandeurMetier: 'CONS' | 'PROD';
    grandeurPhysique:
        | 'PA' // pour les courbes de puissance active
        | 'PRI' // pour les courbes de puissance réactive inductive
        | 'PRC' // pour les courbes de puissance réactive capacitive
        | 'E' // pour les courbes de tension
        | 'PMA' // pour les puissances maximales
        | 'EA'; // pour les données d’énergie active
    unite:
        | 'W' // Pour les données de courbe
        | 'VAr' // Pour les données de courbe
        | 'V' // Pour les données de courbe
        | 'VA' // Pour les données de puissances maximales
        | 'Wh'; // Pour les données d’énergie
    /** liste de mesure 0...200. */
    points: Array<MesureDetailleeExt>;
};
export type GrandeurContexteV3 = {
    /** SI grandeurPhysique === "E" => ne s'appliquer pas.
     *    sens === "INJECTION" || injection === true =>  grandeurMetier: "CONS"
     *    sens === "SOUTIRAGE" || soutirage === true =>  grandeurMetier: "PROD"
     */
    grandeurMetier: 'CONS' | 'PROD';
    grandeurPhysique:
        | 'EA' // pour les données d’énergie active
        | 'ER' // pour les données d’énergie réactive
        | 'ERC' // pour les données d’énergie réactive capacitive
        | 'ERI' // pour les données d’énergie réactive inductive
        | 'DD' // pour la durée de dépassement
        | 'DE' // pour le dépassement énergétique
        | 'DQ' // pour le dépassement quadratique
        | 'PMAX' // pour la puissance maximale atteinte
        | 'TF'; // pour le temps de fonctionnement

    unite:
        | 's' // seconds ?
        | 'W' // Pour les données de courbe
        | 'VAr' // Pour les données de courbe
        | 'V' // Pour les données de courbe
        | 'VA' // Pour les données de puissances maximales
        | 'Wh'; // Pour les données d’énergie
    calendriers: Array<CalendrierEntry>;
    cadransTotalisateur: Array<CadranEntry>;
};
/** Voir la doc éventuellement pour les couplages entre contexteReleve et typeReleve, seuls certains couples sont valides. */
export type Contexte = {
    etapeMetier: 'BEST' | 'BRUT';
    contexteReleve:
        | 'OL' // pour Collecte, C5 P4
        | 'CRD' // pour Compte- Rendu de Demande Ponctuelle, C1-C4 P1- P3
        | 'CRI' // pour Compte-Rendu Intervention, C1-C4 P1- P3 et C5 P4
        | 'FMR' // pour Flux de mesures régulier,C1-C4 P1- P3
        | 'TOP'; // pour Téléopération X, C5 P4
    typeReleve:
        | 'AP' // pour Arrêté asynchrone après
        | 'AQ' // pour Arrêté quotidien
        | 'AS' // pour Arrêté asynchrone
        | 'AV' // pour Arrêté asynchrone avant
        | 'LC' // pour Lecture courante
        | 'RC' // pour Relevé courant
        | 'RM'; // pour Relevé mensuel
    /** si  contexteReleve === "CRI" */
    motifReleve?: 'POSE_FOURNISSEUR' | 'DEPOSE_FOURNISSEUR';
    grandeur?: GrandeurContexteV3;
};
export type ConsultationMesuresDetailleesV3Output = {
    pointId: string;
    /** si demande de type COURBE, PMAX ou ENERGIE. */
    mesuresCorrigees?: 'BEST' | 'BRUT';
    periode: Periode;
    /** seulement pour les demande du type ENERGIE */
    typeValeur?: 'GLOBALE';
    /** si demande de type COURBE, PMAX ou ENERGIE. */
    modeCalcul?: 'MESURE' | 'PROFIL' | 'MESURE' | 'DIFF.INDEX' | 'INTEG.COURBE';
    /** si demande de type PMAX ou ENERGIE. */
    pas?: MesuresPasExt;

    /** (mutuellement exclusif avec contexte) si demande de type COURBE, PMAX ou ENERGIE. */
    grandeur?: GrandeurV3;
    /** (mutuellement exclusif avec grandeur)
     * si demande de type Index. */
    contexte?: Contexte;
};

export const consulterMesuresDetailleesV3 = async (input: ConsulterMesuresDetailleesV3Input): Promise<ConsultationMesuresDetailleesV3Output | undefined> => {
    //return await api.postJsonData('enedis/sge/mesure-detaillee-v3', input);
    let response;
    try {
        response = await api.postJsonData('enedis/sge/mesure-detaillee-v3', input);
    } catch (err) {
        // Erreur 400 passe par ici.
        // == absence de données, ou données non partagées par l'utilisateur.
        // cas fréquent !
        throw err;
    }

    if (!response) {
        // n'arrive jamais concrètement
        throw Error;
    }
    // ca snominal
    return response;
};
//#endregion

//#region consentement

type AuditContractKey = 'rendez_vous' | 'consentement_enedis';

export type CreateContractOutput<DOCTYPE extends AuditContractKey> = {
    workflowKey: 'audit_sogys';
    contractKey: DOCTYPE;
    dossierId: number;
    dossierUuid: string;
    participantUuid: string;
    participantAccessToken: string;
};

export const consentSign = async (flowId: string, pdlNumber: string): Promise<CreateContractOutput<'consentement_enedis'>> => {
    const response = await api.postJsonData(`enedis/consent/sign-particulier`, { flowId, pdlNumber });
    // TODO replace by : (when api prod ready)
    // const response = await api.postJsonData(`enedis/consent/sign`, { flowId, pdlNumber });
    if (!response) throw response;
    return response as CreateContractOutput<'consentement_enedis'>;
};

export type EnedisConsent = {
    id: string;
    createdAt: Date;
    pdl: string;
    expireAt: Date;
    customer: ClientIdentity;
    document: ApiDocument;
};

export const consentDownload = async (flowId: string, pdlNumber: string, netheosUuid: string): Promise<EnedisConsent> => {
    const response = await api.postJsonData(`enedis/consent/download`, { flowId, pdlNumber, netheosUuid });
    if (!response) throw response;
    return response as EnedisConsent;
};

//#endregion
