import React, { useEffect, useState, useRef } from 'react';
import { Formik, FormikErrors } from 'formik';
import { PropertyType } from '../../services/localStorageService';
import * as storageService from '../../services/localStorageService';

import * as enedisCalcul from '../../services/enedis/enedis';

import AddStepPhotos from '../../services/componentsHelpers/addStepPhotos';

import { ReactComponent as IconSpend } from '../../assets/icons/icon-spend.svg';
import { ReactComponent as IconChart } from '../../assets/icons/icon-chart.svg';
import { ReactComponent as IconHelp } from '../../assets/icons/icon-help.svg';

import * as Yup from 'yup';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ROUTE_AUDIT_INFO_RECUEIL_CONSTAT } from '../../routing/paths';
import * as enedisService from '../../services/enedisService';
import { LabelledString, currencyFormat } from '../../services/tools/TypeHelper';
import EnedisGraphic from './EnedisGraph';
import { NetheosEnedis, NetheosEnedisPropsContext } from './component/Netheos';
import CreatableSelect from 'react-select/creatable';
import ReactTooltip from 'react-tooltip';
import { extractNumberFromString } from '../../services/tools/auditTools';
import { useStepNavigation } from '../../routing/StepNavigationContext';

type EnergyCostInformationsValues = {
    energyCostsGas: any;
    energyCostsFuel: any;
    energyCostsWood: any;
    energyCostsGasFuelWood: any;
    energyCostsElectric: any;
    energyCostsSum: any;
    energyCostsElectricInvoice1: any;
    energyCostsElectricInvoice2: any;
    energyCostsElectricInvoice3: any;
    energyCostsGasInvoice1: any;
    energyCostsGasInvoice2: any;
    energyCostsGasInvoice3: any;
};

type MandatoryPhotosProps = {
    energyType: string;
    isRequired: boolean;
    name: string;
    errorMessage: string;
};

const colourStyles = {
    option: (styles: any, { isFocused }: any) => {
        return {
            ...styles,
            backgroundColor: isFocused ? '#76ca4f' : null,
            color: isFocused ? '#ffffff' : '#1F2F3D',
        };
    },
};

const EnergyCostInformations: React.FC = () => {
    const { validateThenRoute, returnToHome } = useStepNavigation();
    const [auditSent, setAuditSent] = useState(false);
    const [pdlList, setPdlListe] = useState<string[] | undefined>(undefined);
    const [, setRerender] = useState<boolean>(false);
    const [puissanceContractuelleSoutirage, setPuissanceContractuelleSoutirage] = useState<string | undefined>(undefined);
    const [heaterSubscription, setHeaterSubscription] = useState<number | null>(null);

    const energyCostsElectricInvoice1 = useRef(null);
    const energyCostsElectricInvoice2 = useRef(null);
    const energyCostsElectricInvoice3 = useRef(null);
    const energyCostsGasInvoice1 = useRef(null);
    const energyCostsGasInvoice2 = useRef(null);
    const energyCostsGasInvoice3 = useRef(null);

    const AlertSwal = withReactContent(Swal);

    // Values from step 3 localStorage
    const step3 = storageService.getAudit().step3;
    const step4 = storageService.getAudit().step4;

    // Power from Enedis or step 3
    useEffect(() => {
        let extractedNumber: number | null = null;

        if (puissanceContractuelleSoutirage !== undefined) {
            extractedNumber = extractNumberFromString(puissanceContractuelleSoutirage);
        } else {
            extractedNumber = extractNumberFromString(step3.heaterSubscription.value_label);
        }

        if (extractedNumber !== null) {
            setHeaterSubscription(extractedNumber);
        }
    }, [puissanceContractuelleSoutirage, step3.heaterSubscription.value_label]);

    /**
     * Valeur lors de l'initialisation de la page
     */
    const initialValues = {
        energyCostsGas: storageService.checkPropertyExistThenCreateOrRender(4, 'energyCostsGas', `Dépense annuelle en gaz`, PropertyType['number']),
        energyCostsFuel: storageService.checkPropertyExistThenCreateOrRender(4, 'energyCostsFuel', `Dépense annuelle en fuel`, PropertyType['number']),
        energyCostsWood: storageService.checkPropertyExistThenCreateOrRender(4, 'energyCostsWood', `Dépense annuelle en bois`, PropertyType['number']),
        energyCostsGasFuelWood: storageService.checkPropertyExistThenCreateOrRender(
            4,
            'energyCostsGasFuelWood',
            `Dépense annuelle en gaz, fioul et/ou bois`,
            PropertyType['number']
        ),
        energyCostsElectric: storageService.checkPropertyExistThenCreateOrRender(
            4,
            'energyCostsElectric',
            `Dépense annuelle en électricité`,
            PropertyType['number']
        ),
        energyCostsSum: storageService.checkPropertyExistThenCreateOrRender(
            4,
            'energyCostsSum',
            `Total des dépenses annuelles énergétiques`,
            PropertyType['number']
        ),
        pdlNumber: storageService.checkPropertyExistThenCreateOrRender(4, 'pdlNumber', `Numéro de PDL`, PropertyType['basic']),
        enedisSigned: storageService.checkPropertyExistThenCreateOrRender(4, 'enedisSigned', `Signature ENEDIS`, PropertyType['boolean']),
        energyCostsElectricInvoice1: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'energyCostsElectricInvoice1',
            `Facture électrique 1`,
            PropertyType['photo']
        ),
        energyCostsElectricInvoice2: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'energyCostsElectricInvoice2',
            `Facture électrique 2`,
            PropertyType['photo']
        ),
        energyCostsElectricInvoice3: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'energyCostsElectricInvoice3',
            `Facture électrique 3`,
            PropertyType['photo']
        ),
        energyCostsGasInvoice1: storageService.checkPropertyExistThenCreateOrRender(8, 'energyCostsGasInvoice1', `Facture de gaz 1`, PropertyType['photo']),
        energyCostsGasInvoice2: storageService.checkPropertyExistThenCreateOrRender(8, 'energyCostsGasInvoice2', `Facture de gaz 2`, PropertyType['photo']),
        energyCostsGasInvoice3: storageService.checkPropertyExistThenCreateOrRender(8, 'energyCostsGasInvoice3', `Facture de gaz 3`, PropertyType['photo']),
    };

    /**
     * Schéma de validation
     */
    const validationSchema = Yup.object({
        energyCostsGas:
            storageService.getAudit().step3.heaterType.value !== '4' && storageService.getAudit().step3.heaterType2.value !== '4'
                ? Yup.number().nullable().notRequired()
                : Yup.number()
                      .min(0, `La dépense minimale annuelle en gaz est de 0`)
                      .typeError(`La saisie de la dépense annuelle en gaz est obligatoire`)
                      .required(`La saisie de la dépense annuelle en gaz est obligatoire`),
        pdlNumber: Yup.string().min(14, 'Le champs PDL est composé de 14 chiffres').notRequired(),
        enedisSigned: Yup.boolean().notRequired(),
        energyCostsFuel:
            storageService.getAudit().step3.heaterType.value !== '6' && storageService.getAudit().step3.heaterType2.value !== '6'
                ? Yup.number().nullable().notRequired()
                : Yup.number()
                      .min(0, `La dépense minimale annuelle en fioul est de 0`)
                      .typeError(`La saisie de la dépense annuelle en fioul est obligatoire`)
                      .required(`La saisie de la dépense annuelle en fioul est obligatoire`),
        energyCostsWood:
            storageService.getAudit().step3.heaterType.value !== '1' && storageService.getAudit().step3.heaterType2.value !== '1'
                ? Yup.number().nullable().notRequired()
                : Yup.number()
                      .min(0, `La dépense minimale annuelle en bois est de 0`)
                      .typeError(`La saisie de la dépense annuelle en bois est obligatoire`)
                      .required(`La saisie de la dépense annuelle en bois est obligatoire`),
        energyCostsGasFuelWood: Yup.number().nullable().notRequired().typeError(''),
        energyCostsElectric: Yup.number()
            .min(0, `La dépense minimale annuelle en électricité est de 0`)
            .typeError('La saisie des dépenses annuelles en électricité est obligatoire')
            .required(`La saisie des dépenses annuelles en électricité est obligatoire`),
        energyCostsSum: Yup.number()
            .required('La somme des dépenses ne peut être calculée sans la saisie de tous les champs')
            .typeError('La somme des dépenses ne peut être calculée sans la saisie de tous les champs'),
        energyCostsElectricInvoice1: Yup.string(),
        energyCostsElectricInvoice2: Yup.string(),
        energyCostsElectricInvoice3: Yup.string(),

        energyCostsGasInvoice1: Yup.string(),
        energyCostsGasInvoice2: Yup.string(),
        energyCostsGasInvoice3: Yup.string(),
    });

    /**
     * Fonction pour mettre à jour un champ dans le local storage ainsi que dans le form
     * @param event (Event provenant de l'input)
     * @param handleChange (Event du formulaire pour mettre à jour une valeur)
     * @param isValid (Event du formulaire pour savoir s'il est valide)
     * @param step (étape)
     * @param propertyName (nom de la propriété)
     * @param propertyList (List des propriétés)
     */
    const updateValue = (event: any, handleChange: any, isValid: boolean, step: number, propertyName: string, propertyList?: any) => {
        // Obtention du type de variable pour écriture
        const type: any = (validationSchema as any).fields[propertyName].type;

        // Mise à jour du formulaire
        if (event.hasOwnProperty('target')) {
            handleChange(event);
        }

        // Mise à jour de la valeur du champ label dans le local storage
        if (propertyList) {
            const findLabel = propertyList.filter((property: any) => {
                if (event.hasOwnProperty('target')) {
                    return property.value === event.target.value;
                }
                return property.value === event.value;
            });
            return storageService.setAuditValue(step, isValid, propertyName, findLabel[0].value, findLabel[0].label);
        }

        // Cas standard
        return storageService.setAuditValue(step, isValid, propertyName, type === 'number' ? parseFloat(event.target.value) : event.target.value);
    };

    const heaterType = step3.heaterType.value;
    const heaterType2 = step3.heaterType2.value;

    const { clientIdentity } = storageService.stepListToAuditAndClient();

    useEffect(() => {
        /**
         * Scroll de la page en haut
         */
        window.scrollTo(0, 0);
        /**
         * Vérification si Audit déjà envoyé
         */
        const template = storageService.getAudit();
        if (template?.auditSent) {
            setAuditSent(true);
        }

        enedisCalcul.searchPdl(clientIdentity).then((pdl: string[]) => {
            if (pdl.length === 0) {
                console.log('aucun pdl correspondant trouvés');
                return;
            }

            if (pdl.length > 1) {
                console.log('plusieurs pdl découverts.');
                setPdlListe(pdl);
                return;
            }

            if (pdl.length === 1) {
                console.log('1 pdl découvert : ' + pdl[0]);
                storageService.setAuditValue(4, true, 'pdlNumber', pdl[0]);

                // ICI, c'est bizare
                // On ne saiit pas _afficher_ le pdlNumber sans déclencher un re-render.
                // Le seul moyen que j'ai trouvé, c'est ce setRerender, qui est un fake state
                // inutile, sauf qu'il déclenche le re-render !
                setRerender(true);
                return;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Mandatory photos
    const mandatoryPhotos: MandatoryPhotosProps[] = [
        {
            energyType: '0',
            isRequired: true,
            name: 'energyCostsElectricInvoice1',
            errorMessage: "photo d'une facture d'électricité",
        },
        {
            energyType: '4',
            isRequired: heaterType === '4' || heaterType2 === '4',
            name: 'energyCostsGasInvoice1',
            errorMessage: "photo d'une facture de gaz",
        },
    ];

    const checkPhotos = (values: any) => {
        let photoCheck = false;

        mandatoryPhotos.forEach((photo: MandatoryPhotosProps) => {
            if (values[photo.name] === '') {
                photoCheck = true;
            }
        });
        return photoCheck;
    };

    const updatePuissanceRaccordementSoutirage = (pdlNumber: string) => {
        enedisCalcul.getPdlDetail(pdlNumber).then((pdlDetail: enedisService.PointTechniqueContractuel) => {
            // console.log('pdldetail = ' + JSON.stringify(pdlDetail, null, 2));
            const puissance1 = pdlDetail.situationContractuelle?.structureTarifaire.puissanceSouscriteMax;
            const puissance2 = pdlDetail.situationAlimentation.alimentationPrincipale.puissanceRaccordementSoutirage;

            if (puissance1) {
                // console.log('puissance de soutirage contractuelle: ' + puissance1.valeur + ' ' + puissance1.unite);
                setPuissanceContractuelleSoutirage(puissance1.valeur + ' ' + puissance1.unite);
            } else if (puissance2) {
                // console.log('puissance de soutirage de raccordement : ' + puissance2.valeur + ' ' + puissance2.unite);
                setPuissanceContractuelleSoutirage(puissance2.valeur + ' ' + puissance2.unite);
            }
        });
    };

    const handlePDLChange = (property: { name: string; value?: string }, setValues: any, values: any, isValid: boolean) => {
        const numericValue = property.value ? property.value.replace(/[^0-9]/g, '') : undefined;

        setValues({
            ...values,
            pdlNumber: numericValue,
        });

        storageService.setAuditValue(4, isValid, 'pdlNumber', numericValue);

        if (numericValue) {
            updatePuissanceRaccordementSoutirage(numericValue);
        }
    };

    const handleEnedisConsent = (values: any, setValues: any, isValid: boolean) => {
        AlertSwal.fire({
            title: 'Demande de consentement',
            html: (
                <>
                    <p>
                        Vous êtes sur le point de demander la collecte de données à Enedis. Cette demande doit être effectuée pour une adresse réelle, et les
                        coordonnées du client doivent impérativement être celles d'un des occupants de la maison (numéro de téléphone, nom, prénom, adresse).
                    </p>
                    <p>
                        Le consentement sera obtenu par la réception d'un code SMS sur le téléphone portable de l'occupant. Si vous effectuez des audits sans le
                        consentement d'un des occupants de l'habitation (numéro de téléphone rattachable à la maison), votre compte sera immédiatement suspendu.
                    </p>
                    <p>
                        <strong>L'équipe Sogys</strong>
                    </p>
                </>
            ),
            width: 700,
            showCancelButton: true,
            focusCancel: true,
            cancelButtonText: 'Revenir à l’audit',
            confirmButtonText: 'Valider ma demande',
            reverseButtons: true,
            customClass: {
                cancelButton: 'btn btn-secondary min-width',
                confirmButton: 'btn btn-green min-width',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                handleEnedisModal(values, setValues, isValid);
            }
        });
    };

    const handleEnedisModal = (values: any, setValues: any, isValid: boolean) => {
        // request rdv
        const pdlNumber: string = values.pdlNumber;

        try {
            if (pdlNumber === undefined) {
                throw new Error('Impossible la demande de consentement sans numéro de pdl');
            }
            // const auditJson = storageService.stepListToAuditAndClient();
            const flowId = localStorage.getItem('flowId');
            if (!flowId) {
                throw new Error("Impossible d'envoyer l'audit, parcours utilisateur inexistant");
            }

            enedisService.consentSign(flowId, pdlNumber).then((emptyNetheosContract) => {
                const context: NetheosEnedisPropsContext = {
                    flowId,
                    pdlNumber,
                    emptyNetheosContract,
                };

                AlertSwal.fire({
                    title: 'Consentement de partage de données enedis',
                    html: <NetheosEnedis token={emptyNetheosContract.participantAccessToken} context={context} onEnedisConsentSigned={onEnedisConsentSigned} />,
                    width: 800,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                }).then((result) => {
                    console.log('THEN');
                    if (result.isConfirmed) {
                        // Update Formik values
                        setValues({
                            ...values,
                            enedisSigned: true,
                        });

                        // Update localStorage value
                        storageService.setAuditValue(4, isValid, 'enedisSigned', true);

                        updatePuissanceRaccordementSoutirage(context.pdlNumber);
                    }
                });
            });
        } catch (error) {
            console.log('consentement impossible a envoyer : ' + error);
            throw error;
        }
    };

    /** Do not call directly.
     * Used as Netheos signature screen callback
     */
    const onEnedisConsentSigned = async (context: NetheosEnedisPropsContext): Promise<boolean> => {
        console.log('onEnedisConsentSigned start download');

        try {
            // Here downlad consentement file
            await enedisService.consentDownload(context.flowId, context.pdlNumber, context.emptyNetheosContract.dossierUuid);

            console.log('download success');
            return true;
        } catch (err) {
            console.log('download failed');
            return false;
        }
    };

    const onClickEtapeSuivante = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        errors: FormikErrors<EnergyCostInformationsValues>,
        values: EnergyCostInformationsValues
    ) => {
        event.preventDefault();
        if (Object.entries(errors).length !== 0) {
            // Préparation liste d'étapes en erreur
            let errorsList: string[] = [];
            Object.keys(errors).forEach((field: any) => {
                // @ts-ignore
                if (errors[field] !== '') {
                    // @ts-ignore
                    errorsList = [...errorsList, `${errors[field]}`];
                }
            });
            // Affichage message
            AlertSwal.fire({
                title: `Champs manquants`,
                html: `<p class="mb-0">Vous ne pouvez pas valider l’étape en cours car certains champs n’ont pas été saisis ou sont en erreur:<br/>
            <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span></p>`,
                cancelButtonText: 'Continuer la saisie',
                showConfirmButton: false,
                showCancelButton: true,
                focusCancel: true,
                showClass: {
                    popup: 'errors',
                    backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                },
                customClass: {
                    cancelButton: 'btn btn-primary maxi-min-width',
                },
            });
        } else {
            if (checkPhotos(values)) {
                // Préparation liste des photos en erreur
                let errorsList: string[] = [];
                mandatoryPhotos.forEach((photo: MandatoryPhotosProps) => {
                    // @ts-ignore
                    if (values[photo.name].length === 0 && photo.isRequired) {
                        // @ts-ignore
                        errorsList = [...errorsList, `${photo.errorMessage}`];
                    }
                });

                if (errorsList.length > 0) {
                    // Affichage message
                    AlertSwal.fire({
                        title: `Photos manquantes`,
                        html: `<p class="mb-0">Les photos suivantes n’ont pas été insérées :<br/>
                    <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span>
                    <br/>
                    Vous pouvez poursuivre le remplissage de l’audit mais il sera nécessaire de les ajouter à l’étape 8 pour sa validation finale 
                    </p>`,
                        cancelButtonText: 'Rester sur l’étape',
                        confirmButtonText: 'Accepter',
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        focusCancel: true,
                        showClass: {
                            popup: 'errors',
                            backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                        },
                        customClass: {
                            cancelButton: 'btn btn-primary maxi-min-width',
                            confirmButton: 'btn btn-secondary maxi-min-width',
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            validateThenRoute(4, ROUTE_AUDIT_INFO_RECUEIL_CONSTAT);
                        }
                    });
                } else {
                    validateThenRoute(4, ROUTE_AUDIT_INFO_RECUEIL_CONSTAT);
                }
            } else {
                validateThenRoute(4, ROUTE_AUDIT_INFO_RECUEIL_CONSTAT);
            }
        }
    };

    // Don't touche this any
    const handleNumberInput = (e: any, setValues: any, values: any, isValid: boolean) => {
        const keyPressed = e.key;

        if (keyPressed === 'Delete' || keyPressed === 'Backspace' || keyPressed === 'Enter') {
            return;
        }

        // cmd/ctrl + a
        if ((e.metaKey || e.ctrlKey) && keyPressed === 'a') {
            return;
        }

        // Limit to 14 characters max
        const inputValue = (e.target as HTMLInputElement).value;
        if (inputValue.length >= 14 || isNaN(Number(keyPressed))) {
            e.preventDefault();
        }

        // Validate when 14 characters are filled
        // if (inputValue.length > 13) {
        //     handlePDLChange({ name: 'pdlNumber', value: inputValue }, setValues, values, isValid);
        // }
    };

    return (
        <Formik
            onSubmit={() => {}}
            initialValues={{ ...initialValues, pdlNumber: step4.pdlNumber?.value || undefined }}
            isInitialValid={storageService.getAudit().step4.isValid}
            validateOnMount={true}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {({ values, errors, handleChange, isValid, setValues, setFieldValue, touched, handleBlur }) => {
                // Update formik values
                storageService.setAuditStepValidity(4, isValid);
                // Update global steps
                if (isValid) {
                    let globalCurrentStep = storageService.getAuditCurrentStep();
                    if (4 === globalCurrentStep) {
                        storageService.setAuditCurrentStep(globalCurrentStep + 1);
                    }
                }

                return (
                    <div className="container">
                        <h1 className="main-title-mini">4. Dépenses énergétiques</h1>
                        <h2 className="main-subtitle">Dépense par type d'énergie</h2>
                        <div className="card mb-5">
                            <div className="card-title">
                                <IconSpend fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Décomposition des dépenses énergétiques</h2>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6 mb-3">
                                            <label htmlFor="energyCostsElectric" className="form-label">
                                                Dépense annuelle en électricité<sup>*</sup>
                                            </label>
                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    className={
                                                        'form-control' +
                                                        (values.energyCostsElectric || values.energyCostsElectric === 0 ? ' filled' : '') +
                                                        (touched.energyCostsElectric && errors.energyCostsElectric ? ' invalid' : '') +
                                                        (!values.energyCostsElectric ? ' required' : '')
                                                    }
                                                    placeholder="1150"
                                                    id="energyCostsElectric"
                                                    onBlur={handleBlur}
                                                    min={0}
                                                    value={values?.energyCostsElectric || ''}
                                                    onChange={(event) => {
                                                        updateValue(event, handleChange, isValid, 4, 'energyCostsElectric');
                                                        const asFloat = parseFloat(event.target.value);
                                                        const newValue = isNaN(asFloat) ? 0 : asFloat;
                                                        // Calcul du total
                                                        const energyCostsGasFuelWood =
                                                            parseFloat(values.energyCostsGas ? values.energyCostsGas : 0) +
                                                            parseFloat(values.energyCostsFuel ? values.energyCostsFuel : 0) +
                                                            parseFloat(values.energyCostsWood ? values.energyCostsWood : 0);
                                                        const energyCostsSum = newValue + energyCostsGasFuelWood;
                                                        // Injection dans Formik
                                                        setValues({
                                                            ...values,
                                                            energyCostsElectric: newValue,
                                                            energyCostsSum,
                                                            energyCostsGasFuelWood,
                                                        });
                                                        // Injection dans local storage
                                                        storageService.setAuditValue(4, isValid, 'energyCostsGasFuelWood', energyCostsGasFuelWood);
                                                        storageService.setAuditValue(4, isValid, 'energyCostsSum', energyCostsSum);
                                                    }}
                                                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                />
                                                <span className="input-group-text">€</span>
                                            </div>

                                            <div className="invoice-group invoice-column pt-2">
                                                <small className="invoice-info">
                                                    La facture la plus récente est obligatoire mais il est recommandé d’insérer 3 années de facture de
                                                    consommation
                                                </small>
                                                <AddStepPhotos
                                                    prop={energyCostsElectricInvoice1}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue={'energyCostsElectricInvoice1'}
                                                    disabled={auditSent}
                                                    required={true}
                                                />

                                                <AddStepPhotos
                                                    prop={energyCostsElectricInvoice2}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue={'energyCostsElectricInvoice2'}
                                                    disabled={values.energyCostsElectricInvoice1 === ''}
                                                />

                                                <AddStepPhotos
                                                    prop={energyCostsElectricInvoice3}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue={'energyCostsElectricInvoice3'}
                                                    disabled={values.energyCostsElectricInvoice2 === ''}
                                                />
                                            </div>
                                            {touched.energyCostsElectric && errors.energyCostsElectric && (
                                                <div className="invalid-feedback">{errors.energyCostsElectric}</div>
                                            )}
                                        </div>
                                        {(storageService.getAudit().step3.heaterType.value === '4' ||
                                            storageService.getAudit().step3.heaterType2.value === '4') && (
                                            <div className="col-12 col-md-6 mb-3">
                                                <label htmlFor="energyCostsGas" className="form-label label-flex">
                                                    Dépense annuelle en gaz
                                                    {(validationSchema.describe().fields['energyCostsGas'] as any).tests.length > 0 && <sup>*</sup>}
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        className={
                                                            'form-control' +
                                                            (values.energyCostsGas || values.energyCostsGas === 0 ? ' filled' : '') +
                                                            (touched.energyCostsGas && errors.energyCostsGas ? ' invalid' : '') +
                                                            (!values.energyCostsGas ? ' required' : '')
                                                        }
                                                        placeholder="1150"
                                                        disabled={
                                                            storageService.getAudit().step3.heaterType.value !== '4' &&
                                                            storageService.getAudit().step3.heaterType2.value !== '4'
                                                        }
                                                        id="energyCostsGas"
                                                        onBlur={handleBlur}
                                                        min={0}
                                                        value={values?.energyCostsGas || ''}
                                                        onChange={(event) => {
                                                            updateValue(event, handleChange, isValid, 4, 'energyCostsGas');
                                                            const asFloat = parseFloat(event.target.value);
                                                            const newValue = isNaN(asFloat) ? 0 : asFloat;
                                                            // Calcul du total
                                                            const energyCostsGasFuelWood =
                                                                newValue +
                                                                parseFloat(values.energyCostsFuel ? values.energyCostsFuel : 0) +
                                                                parseFloat(values.energyCostsWood ? values.energyCostsWood : 0);
                                                            const energyCostsSum =
                                                                parseFloat(values.energyCostsElectric ? values.energyCostsElectric : 0) +
                                                                energyCostsGasFuelWood;
                                                            // Injection dans Formik
                                                            setValues({
                                                                ...values,
                                                                energyCostsGas: newValue,
                                                                energyCostsSum,
                                                                energyCostsGasFuelWood,
                                                            });
                                                            // Injection dans local storage
                                                            storageService.setAuditValue(4, isValid, 'energyCostsGasFuelWood', energyCostsGasFuelWood);
                                                            storageService.setAuditValue(4, isValid, 'energyCostsSum', energyCostsSum);
                                                        }}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">€</span>
                                                </div>
                                                <div className="invoice-group invoice-column pt-2">
                                                    <small className="invoice-info">
                                                        La facture la plus récente est obligatoire mais il est recommandé d’insérer 3 années de facture de
                                                        consommation
                                                    </small>
                                                    <AddStepPhotos
                                                        prop={energyCostsGasInvoice1}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'energyCostsGasInvoice1'}
                                                        required={true}
                                                    />

                                                    <AddStepPhotos
                                                        prop={energyCostsGasInvoice2}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'energyCostsGasInvoice2'}
                                                        disabled={values.energyCostsGasInvoice1 === ''}
                                                    />

                                                    <AddStepPhotos
                                                        prop={energyCostsGasInvoice3}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'energyCostsGasInvoice3'}
                                                        disabled={values.energyCostsGasInvoice2 === ''}
                                                    />
                                                </div>
                                                {touched.energyCostsGas && errors.energyCostsGas && (
                                                    <div className="invalid-feedback">{errors.energyCostsGas}</div>
                                                )}
                                            </div>
                                        )}
                                        {(storageService.getAudit().step3.heaterType.value === '6' ||
                                            storageService.getAudit().step3.heaterType2.value === '6') && (
                                            <div className="col-12 col-md-6 mb-3">
                                                <label htmlFor="energyCostsFuel" className="form-label">
                                                    Dépense annuelle en fioul
                                                    {(validationSchema.describe().fields['energyCostsFuel'] as any).tests.length > 0 && <sup>*</sup>}
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        className={
                                                            'form-control' +
                                                            (values.energyCostsFuel || values.energyCostsFuel === 0 ? ' filled' : '') +
                                                            (touched.energyCostsFuel && errors.energyCostsFuel ? ' invalid' : '') +
                                                            (!values.energyCostsFuel ? ' required' : '')
                                                        }
                                                        placeholder="1150"
                                                        disabled={
                                                            storageService.getAudit().step3.heaterType.value !== '6' &&
                                                            storageService.getAudit().step3.heaterType2.value !== '6'
                                                        }
                                                        id="energyCostsFuel"
                                                        onBlur={handleBlur}
                                                        min={0}
                                                        value={values?.energyCostsFuel || ''}
                                                        onChange={(event) => {
                                                            updateValue(event, handleChange, isValid, 4, 'energyCostsFuel');
                                                            const asFloat = parseFloat(event.target.value);
                                                            const newValue = isNaN(asFloat) ? 0 : asFloat;
                                                            // Calcul du total
                                                            const energyCostsGasFuelWood =
                                                                parseFloat(values.energyCostsGas ? values.energyCostsGas : 0) +
                                                                newValue +
                                                                parseFloat(values.energyCostsWood ? values.energyCostsWood : 0);
                                                            const energyCostsSum =
                                                                parseFloat(values.energyCostsElectric ? values.energyCostsElectric : 0) +
                                                                energyCostsGasFuelWood;
                                                            // Injection dans Formik
                                                            setValues({
                                                                ...values,
                                                                energyCostsFuel: newValue,
                                                                energyCostsGasFuelWood,
                                                                energyCostsSum,
                                                            });
                                                            // Injection dans local storage
                                                            storageService.setAuditValue(4, isValid, 'energyCostsGasFuelWood', energyCostsGasFuelWood);
                                                            storageService.setAuditValue(4, isValid, 'energyCostsSum', energyCostsSum);
                                                        }}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">€</span>
                                                </div>
                                                {touched.energyCostsFuel && errors.energyCostsFuel && (
                                                    <div className="invalid-feedback">{errors.energyCostsFuel}</div>
                                                )}
                                            </div>
                                        )}
                                        {(storageService.getAudit().step3.heaterType.value === '1' ||
                                            storageService.getAudit().step3.heaterType2.value === '1') && (
                                            <div className="col-12 col-md-6 mb-3">
                                                <label htmlFor="energyCostsWood" className="form-label">
                                                    Dépense annuelle en bois
                                                    {(validationSchema.describe().fields['energyCostsWood'] as any).tests.length > 0 && <sup>*</sup>}
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        className={
                                                            'form-control' +
                                                            (values.energyCostsWood || values.energyCostsWood === 0 ? ' filled' : '') +
                                                            (touched.energyCostsWood && errors.energyCostsWood ? ' invalid' : '') +
                                                            (!values.energyCostsWood ? ' required' : '')
                                                        }
                                                        placeholder="1150"
                                                        disabled={
                                                            storageService.getAudit().step3.heaterType.value !== '1' &&
                                                            storageService.getAudit().step3.heaterType2.value !== '1'
                                                        }
                                                        id="energyCostsWood"
                                                        onBlur={handleBlur}
                                                        min={0}
                                                        value={values?.energyCostsWood || ''}
                                                        onChange={(event) => {
                                                            updateValue(event, handleChange, isValid, 4, 'energyCostsWood');
                                                            const asFloat = parseFloat(event.target.value);
                                                            const newValue = isNaN(asFloat) ? 0 : asFloat;
                                                            // Calcul du total
                                                            const energyCostsGasFuelWood =
                                                                parseFloat(values.energyCostsGas ? values.energyCostsGas : 0) +
                                                                parseFloat(values.energyCostsFuel ? values.energyCostsFuel : 0) +
                                                                newValue;
                                                            const energyCostsSum =
                                                                parseFloat(values.energyCostsElectric ? values.energyCostsElectric : 0) +
                                                                energyCostsGasFuelWood;
                                                            // Injection dans Formik
                                                            setValues({
                                                                ...values,
                                                                energyCostsWood: newValue,
                                                                energyCostsGasFuelWood,
                                                                energyCostsSum,
                                                            });
                                                            // Injection dans local storage
                                                            storageService.setAuditValue(4, isValid, 'energyCostsGasFuelWood', energyCostsGasFuelWood);
                                                            storageService.setAuditValue(4, isValid, 'energyCostsSum', energyCostsSum);
                                                        }}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">€</span>
                                                </div>
                                                {touched.energyCostsWood && errors.energyCostsWood && (
                                                    <div className="invalid-feedback">{errors.energyCostsWood}</div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="energyCostsSum" className="form-label mb-0">
                                                Total des dépenses annuelles énergétiques {values.energyCostsSum}
                                            </label>
                                            <input
                                                type="text"
                                                style={{ fontSize: '1.25rem' }}
                                                className={'form-control-plaintext' + (values.energyCostsSum === 0 ? ' red' : '')}
                                                readOnly={true}
                                                placeholder="4150"
                                                id="energyCostsSum"
                                                onBlur={handleBlur}
                                                value={currencyFormat(values.energyCostsSum)}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconChart fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Visualisation de votre consommation</h2>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100">
                                    <div className="row align-items-end mb-3">
                                        <div className="col-12 col-md-4 mb-3 mb-lg-0">
                                            <label htmlFor="pdlNumber" className="form-label">
                                                Numéro de PDL
                                                <div className="help-text" data-tip data-for="pdl">
                                                    <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                </div>
                                            </label>
                                            {pdlList !== undefined ? (
                                                <CreatableSelect
                                                    value={values.pdlNumber !== undefined ? { label: values.pdlNumber, value: values.pdlNumber } : undefined}
                                                    options={pdlList.map((pdl: string) => {
                                                        return { value: pdl, label: pdl };
                                                    })}
                                                    formatCreateLabel={(userInput) => `Ajouter : ${userInput}`}
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    onBlur={handleBlur}
                                                    onChange={(value: LabelledString | null) => {
                                                        handlePDLChange({ name: 'pdlNumber', value: value?.value }, setValues, values, isValid);
                                                    }}
                                                    onKeyDown={(e) => handleNumberInput(e, setValues, values, isValid)}
                                                    className={
                                                        'basic-single' +
                                                        (values.pdlNumber ? ' filled' : '') +
                                                        (touched.pdlNumber && errors.pdlNumber ? ' invalid' : '')
                                                    }
                                                    placeholder={'Numéro de PDL'}
                                                    styles={colourStyles}
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    placeholder="Numéro de PDL"
                                                    id="pdlNumber"
                                                    name="pdlNumber"
                                                    className={
                                                        'form-control' +
                                                        (values.pdlNumber ? ' filled' : '') +
                                                        (touched.pdlNumber && errors.pdlNumber ? ' invalid' : '')
                                                    }
                                                    value={values.pdlNumber}
                                                    onBlur={handleBlur}
                                                    maxLength={14}
                                                    disabled={auditSent}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                        handlePDLChange({ name: event.target.name, value: event.target.value }, setValues, values, isValid)
                                                    }
                                                />
                                            )}
                                            {touched.pdlNumber && errors.pdlNumber && <div className="invalid-feedback">{errors.pdlNumber}</div>}
                                        </div>
                                        <ReactTooltip id="pdl" place="bottom" type={'light'} effect={'solid'}>
                                            <h3>Numéro de PDL :</h3>
                                            <p>
                                                Le PDL (Point de livraison) est un identifiant unique relatif à votre habitation.
                                                <br />
                                                Vous pouvez le trouver sur votre facture d’électricité
                                            </p>
                                        </ReactTooltip>
                                        <div className="col-12 col-md-8">
                                            <div className="row align-items-end">
                                                <div
                                                    className="col-12 col-lg-4 col-md-6 mb-3 mb-lg-0"
                                                    style={values.pdlNumber === undefined ? { cursor: 'not-allowed' } : undefined}
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn btn-enedis w-100"
                                                        onClick={() => handleEnedisConsent(values, setValues, isValid)}
                                                        disabled={auditSent || values.enedisSigned || values.pdlNumber === undefined}
                                                        data-valid={values.enedisSigned}
                                                    >
                                                        Collecte de vos données
                                                    </button>
                                                </div>
                                                <div className="col-12 col-lg-8 col-md-6 align-items-center">
                                                    <p className="enedis-laius mb-0">
                                                        Cette action vous permet d’autoriser Enedis à partager vos données collectées directement avec SOGYS.
                                                        <br />
                                                        Cela nous permettra de vous proposer la solution la plus pertinente au regard de vos données de
                                                        consommation réelles.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-12 col-md-4">
                                            <label htmlFor="power">Puissance</label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    placeholder="Puissance"
                                                    id="power"
                                                    name="power"
                                                    className="form-control"
                                                    value={
                                                        heaterSubscription !== null && values.pdlNumber !== undefined && values.enedisSigned
                                                            ? heaterSubscription.toString()
                                                            : ''
                                                    }
                                                    disabled
                                                />
                                                <span className="input-group-text">kVA</span>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                {values.enedisSigned && values.pdlNumber ? (
                                    <div className="w-100 graph">
                                        <EnedisGraphic />
                                    </div>
                                ) : (
                                    <div className="w-100 graph">
                                        <IconChart fill="#C4C4C4" width={60} height={60} />
                                        <p className="mb-0">En attente d'autorisation afin d'afficher des données</p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="navigation text-center">
                            <button className={'btn btn-secondary maxi-min-width mx-2 mb-3'} onClick={() => returnToHome(4, '/audit')}>
                                Retour au menu principal
                            </button>
                            <button
                                type="button"
                                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                    onClickEtapeSuivante(event, errors, values);
                                }}
                                className={'btn btn-green maxi-min-width mx-2 mb-3'}
                            >
                                Étape suivante
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default EnergyCostInformations;
