import React from 'react';
import Cookies from 'js-cookie';

const FinDeCommande: React.FC = () => {
    const companyLogo = Cookies.getJSON('Auth').companyLogo;

    return (
        <div className="container">
            <div className="py-5"></div>
            <div className="row justify-content-center">
                <div className="signature-logos mt-3 mb-5">
                    <div className="main-logo">
                        <img src={companyLogo} alt="Logicoll" aira-hidden="true" />
                    </div>
                </div>
                <div className="col-12 col-md-8">
                    <h1 className="main-title-mini">Merci !</h1>
                    <p>Votre commande est désormais finalisée.</p>
                    <p className="mb-5">
                        Nos équipes reprendront rapidement contact avec vous afin de finaliser les
                        <br />
                        dernières modalités de votre installation.
                    </p>
                    <p className="text-end mb-0">Equipe SOGYS</p>
                </div>
            </div>
        </div>
    );
};

export default FinDeCommande;
