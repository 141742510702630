import React, { useEffect, useRef, useState } from 'react';
import useImage from 'use-image';
import { Layer, Stage, Image, Rect, Group, Line, Circle } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Dot, STAGE_HEIGHT_DP5, STAGE_WIDTH_DP5 } from '../../../../../services/tools/konvaTools';
import { DP5Props, MyKonvaImageProps } from './DP5Enums';
import Perspective from 'perspectivets';
import { useFormikContext } from 'formik';
import { FormValues } from '../../PhotovoltaiqueEnums';

// Style
import './DP5.scss';

const MyKonvaImage: React.FC<MyKonvaImageProps> = ({ item, index, layer, setValues, values }) => {
    const imageToDisplay = item.image;
    const [image] = useImage(imageToDisplay ?? '', 'anonymous');
    const [showCircle, setShowCircle] = useState<boolean>(false);

    const onCircleMove = (e: KonvaEventObject<MouseEvent>, dotIndex: number) => {
        const target = e.target;
        const updatedDot: Dot = { x: target.x(), y: target.y(), isHovered: false };

        const newHistory = values.historyDP5.map((item, idx) => {
            if (idx === index) {
                const newItem = { ...item };
                newItem.drawingElements.dots[dotIndex] = updatedDot;
                return newItem;
            }
            return item;
        });

        // Mettre à jour l'état du formulaire avec les nouvelles valeurs
        setValues({ ...values, historyDP5: newHistory });

        // setMapHistory((prevHistory) => {
        //     const updatedHistory = [...prevHistory];
        //     const updatedItem = { ...updatedHistory[index] };

        //     updatedItem.drawingElements.dots[dotIndex] = updatedDot;
        //     updatedHistory[index] = updatedItem;

        //     return updatedHistory;
        // });
    };

    const canvas = React.useMemo(() => {
        if (!image || !layer.current) {
            return;
        }
        const cnv = document.createElement('canvas');
        if (!cnv) {
            return;
        }
        const ctx = cnv.getContext('2d');
        if (!ctx) {
            return;
        }
        const minX = Math.min(...item.drawingElements.dots.map((d) => d.x));
        const minY = Math.min(...item.drawingElements.dots.map((d) => d.y));

        // const maxX = Math.max(...item.drawingElements.dots.map((d) => d.x));
        // const maxY = Math.max(...item.drawingElements.dots.map((d) => d.y));
        // const width = maxX - minX;
        // const height = maxY - minY;

        cnv.width = STAGE_WIDTH_DP5;
        cnv.height = STAGE_HEIGHT_DP5;
        ctx.save();
        ctx.translate(minX, minY);
        const p = new Perspective(ctx, image);

        p.draw({
            topLeftX: item.drawingElements.dots[0].x - minX,
            topLeftY: item.drawingElements.dots[0].y - minY,
            bottomLeftX: item.drawingElements.dots[1].x - minX,
            bottomLeftY: item.drawingElements.dots[1].y - minY,
            bottomRightX: item.drawingElements.dots[2].x - minX,
            bottomRightY: item.drawingElements.dots[2].y - minY,
            topRightX: item.drawingElements.dots[3].x - minX,
            topRightY: item.drawingElements.dots[3].y - minY,
        });
        ctx.restore();
        layer.current.batchDraw();
        return cnv;
    }, [item, image, layer]);

    // It is needed I don't know why... otherwise hitwidth is wrong
    const imgHitFunc = () => null;

    const onDotEnter = (dotIndex: number) => {
        const newHistory = values.historyDP5.map((item, idx) => {
            if (idx === index) {
                const newItem = { ...item };
                if (newItem.drawingElements && newItem.drawingElements.dots) {
                    // Mise à jour du dot spécifique
                    newItem.drawingElements.dots[dotIndex] = {
                        ...newItem.drawingElements.dots[dotIndex],
                        isHovered: true,
                    };
                }
                return newItem;
            }
            return item;
        });

        setValues({ ...values, historyDP5: newHistory });
        // setMapHistory((prevHistory) => {
        //     const updatedHistory = [...prevHistory];
        //     const updatedItem = { ...updatedHistory[index] };
        //     if (updatedItem.drawingElements && updatedItem.drawingElements.dots) {
        //         updatedItem.drawingElements.dots[dotIndex] = {
        //             ...updatedItem.drawingElements.dots[dotIndex],
        //             isHovered: true,
        //         };
        //         updatedHistory[index] = updatedItem;
        //     }
        //     return updatedHistory;
        // });

        document.body.style.cursor = 'pointer'; // Change cursor to pointer when hovering over a dot
    };

    const onDotLeave = (dotIndex: number) => {
        const newHistory = values.historyDP5.map((item, idx) => {
            if (idx === index) {
                const newItem = { ...item };
                if (newItem.drawingElements && newItem.drawingElements.dots) {
                    // Mise à jour du dot spécifique avec isHovered à false
                    newItem.drawingElements.dots[dotIndex] = {
                        ...newItem.drawingElements.dots[dotIndex],
                        isHovered: false,
                    };
                }
                return newItem;
            }
            return item;
        });

        setValues({ ...values, historyDP5: newHistory });
        // setMapHistory((prevHistory) => {
        //     const updatedHistory = [...prevHistory];
        //     const updatedItem = { ...updatedHistory[index] };
        //     if (updatedItem.drawingElements && updatedItem.drawingElements.dots) {
        //         updatedItem.drawingElements.dots[dotIndex] = {
        //             ...updatedItem.drawingElements.dots[dotIndex],
        //             isHovered: false,
        //         };
        //         updatedHistory[index] = updatedItem;
        //     }
        //     return updatedHistory;
        // });

        document.body.style.cursor = 'default'; // Change cursor back to default when not hovering over a dot
    };

    return (
        <Group
            draggable={item.drawingElements?.dots.find((dot) => dot.isHovered !== false) === undefined}
            onMouseEnter={() => setShowCircle(true)}
            onMouseLeave={() => setShowCircle(false)}
        >
            {item.type !== 'auvent' && <Image image={canvas} hitFunc={imgHitFunc} />}
            <Line
                points={item.drawingElements?.dots.flatMap((dot) => [dot.x, dot.y])}
                stroke={item.color ?? '#000'}
                strokeWidth={showCircle ? 2 : 0}
                fillPatternImage={item.type === 'auvent' ? image : undefined}
                closed={item.drawingElements.completed}
                hitStrokeWidth={20}
            />
            {showCircle &&
                item.drawingElements?.dots.map((dot, dotIndex) => {
                    return (
                        <Circle
                            key={dotIndex}
                            x={dot.x}
                            y={dot.y}
                            draggable
                            radius={5}
                            fill={item.color ?? '#000'}
                            onMouseEnter={() => onDotEnter(dotIndex)}
                            onMouseLeave={() => onDotLeave(dotIndex)}
                            onDragMove={(e) => onCircleMove(e, dotIndex)}
                            stroke={item.color ?? '#000'}
                            strokeWidth={dot.isHovered ? 5 : 1}
                        />
                    );
                })}
        </Group>
    );
};

export const DP5: React.FC<DP5Props> = ({ background, setSelectedElementIndex, layerRef, stageRef }) => {
    const { values, setValues } = useFormikContext<FormValues>() ?? {};
    const [dp5Background] = useImage(background);
    const rectRef = useRef(null);
    const [canvasSize, setCanvasSize] = useState<{ width: number; height: number }>({ width: STAGE_WIDTH_DP5, height: STAGE_HEIGHT_DP5 });

    // Resize
    const aspectRatio: number = STAGE_HEIGHT_DP5 / STAGE_WIDTH_DP5;

    useEffect(() => {
        const windowWidth = window.innerWidth;
        const handleResize = () => {
            if (windowWidth <= 1024) {
                setCanvasSize({ width: 500, height: 500 * aspectRatio });
            } else {
                setCanvasSize({ width: STAGE_WIDTH_DP5, height: STAGE_HEIGHT_DP5 });
            }
        };

        console.log(windowWidth);
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Add image and keep ratio
    let imgWidth: number = 0;
    let imgHeight: number = 0;

    if (dp5Background) {
        const imgAspectRatio = dp5Background.width / dp5Background.height;

        // If image's aspect ratio is less than stage's aspect ratio
        // then fit to height and adjust width
        if (imgAspectRatio < aspectRatio) {
            imgHeight = canvasSize.height;
            imgWidth = imgHeight * imgAspectRatio;
        } else {
            imgWidth = STAGE_WIDTH_DP5;
            imgHeight = canvasSize.width / imgAspectRatio;
        }
    }

    const checkDeselect = (e: any) => {
        // deselect when clicked on empty area
        if (rectRef.current && e.target === rectRef.current) {
            setSelectedElementIndex(null);
        }
    };

    return (
        <Stage width={canvasSize.width} height={canvasSize.height} cornerRadius={10} ref={stageRef}>
            <Layer ref={layerRef}>
                <Image
                    image={dp5Background}
                    width={imgWidth}
                    height={imgHeight}
                    cornerRadius={8}
                    y={(canvasSize.height - imgHeight) / 2}
                    x={(canvasSize.width - imgWidth) / 2}
                />
                <Rect
                    width={canvasSize.width}
                    height={canvasSize.height}
                    cornerRadius={10}
                    onMouseDown={checkDeselect}
                    onTouchStart={checkDeselect}
                    ref={rectRef}
                />
                {values.historyDP5.map((item, index) => {
                    if (item.deleted) {
                        return null;
                    }

                    return (
                        <React.Fragment key={item.id}>
                            <MyKonvaImage item={item} index={index} setValues={setValues} values={values} layer={layerRef} />
                        </React.Fragment>
                    );
                })}
            </Layer>
        </Stage>
    );
};
