import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as storageService from '../../services/localStorageService';

import CheckboxService from '../../components/checkbox/checkboxService';
import { authorized } from './services/handleCheckbox';

import * as Yup from 'yup';
import { Formik } from 'formik';
import * as apiParticulierService from '../../services/apiParticulierService';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

import './rendezVous.scss';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import { sendAllImages } from '../../services/tools/imageHelper';
import { ROUTE_AUDIT_RDV_SIGNATURE, ROUTE_SIM_SYNTHESE } from '../../routing/paths';

// Icons
import { ReactComponent as IconLoader } from '../../assets/icons/loader.svg';

const RdvTimeNames = ['now', 'later', ''] as const;
type RdvTime = (typeof RdvTimeNames)[number];

type RDV = {
    rendezVousNow: RdvTime;
    date: Date;
    time: Date;
    authorized: '' | 'true' | 'false';
    declarer: string;
    agent: string;
    signedAt: string;
    place: string;
};

const useCounter = (maxTime: number) => {
    const [counter, setCounter] = useState(maxTime);

    useEffect(() => {
        let indexCounter: any = null;

        if (counter > 0) {
            indexCounter = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        }

        return () => clearInterval(indexCounter);
    }, [counter]);

    return counter;
};

// Display HTML + counter
const SwalComponent = () => {
    const counter = useCounter(15);

    return (
        <div>
            Merci de patienter...
            <div className="swal2-loader mt-3 mx-auto d-flex"></div>
            <p className="mt-3">{counter}</p>
        </div>
    );
};

const RendezVous: React.FC = () => {
    const { push } = useHistory();
    const AlertSwal = withReactContent(Swal);

    const [loading, setLoading] = useState(false);

    const agentCode = Cookies.getJSON('Auth').agentCode;
    const agentPassword = Cookies.getJSON('Auth').agentPassword;

    const stepMinutes: number = 15;

    /**
     * City to display in `place` input
     */
    const userCity = storageService.getAudit().step1.userLocality.value;

    useEffect(() => {
        const template = storageService.getAudit();

        window.scrollTo(0, 0);
        /**
         * Vérification si Audit déjà envoyé
         */
        if (template?.auditSent) {
            return;
        }

        // Affichage écran de chargement
        AlertSwal.fire({
            title: 'Envoi des photos en cours',
            html: `Merci de patienter...`,
            didOpen: () => {
                Swal.showLoading();
            },
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            focusConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
        /**
         * envoie les images et les plans à l'api, et sauvergarde les urls dans le local storage
         */
        sendAllImages().then((response: any) => {
            // Fermeture écran de chargement
            setTimeout(() => {
                Swal.close();
            }, 1000);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Fonction pour calculer automatiquement le prochain quart d'heure
     * @param minutes
     * @param date
     */
    const getRoundedDate = (minutes: number, date: Date = new Date()) => {
        let ms = 1000 * 60 * minutes; // convert minutes to ms
        return new Date(Math.round(date.getTime() / ms) * ms);
    };

    /**
     * Valeurs du formulaire
     */

    // Date du jour en format 'yyyy-mm-dd'
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    const currentDay = yyyy + '-' + mm + '-' + dd;

    const initialValues: RDV = {
        rendezVousNow: 'now',
        date: new Date(),
        time: getRoundedDate(30),
        authorized: '',
        declarer: storageService.getAudit()?.step1?.userFirstname?.value + ' ' + storageService.getAudit()?.step1?.userLastname?.value,
        agent: Cookies.getJSON('Auth').nom,
        signedAt: currentDay,
        place: userCity !== '' ? userCity : '',
    };

    /**
     * Schéma de validation du formulaire
     */
    const validationSchema = Yup.object().shape({
        rendezVousNow: Yup.mixed<RdvTime>().oneOf(Object.values(RdvTimeNames)).required(`Le choix du rendez-vous est obligatoire`),
        date: Yup.string().required(`La date est obligatoire`),
        time: Yup.string().required(`L'heure est obligatoire`),
        authorized: Yup.boolean()
            .notRequired()
            .when('rendezVousNow', {
                is: (value: RdvTime) => value === 'later',
                then: Yup.boolean().oneOf([true]).required("l'autorisatione est obligatoire"),
            }),
        declarer: Yup.string().required(`Votre prénom et votre nom sont obligatoires`),
        agent: Yup.string().required(`Le nom de l'agent est obligatoire`),
        signedAt: Yup.date().required(`La date est obligatoire`),
        place: Yup.string().min(2, `Ce champs doit contenir au moins 2 caractères`).required(`Ce champs est obligatoire`),
    });

    /**
     * Fonction pour l'envoi de la requête du rendez-vous
     */
    const sendAudit = async () => {
        try {
            const flowId = localStorage.getItem('flowId');

            if (!flowId) {
                throw new Error("Impossible d'envoyer l'audit, parcours utilisateur inexistant");
            }
            // Lancement de la requête
            return apiParticulierService.sendAudit(flowId);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    /**
     * Fonction pour l'envoi de la requête du rendez-vous
     */
    const requestRdv = async (values: any) => {
        // Affichage écran de chargement
        AlertSwal.fire({
            title: `Envoi de l'audit en cours`,
            html: <SwalComponent />,
            // didOpen: () => {
            //     Swal.showLoading();
            // },
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
            focusConfirm: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });

        try {
            // const auditJson = storageService.stepListToAuditAndClient();
            const flowId = localStorage.getItem('flowId');
            if (!flowId) {
                throw new Error("Impossible d'envoyer l'audit, parcours utilisateur inexistant");
            }

            let auditAndRdv: any = {
                // clientIdentity: auditJson.clientIdentity,
                // audit: auditJson.audit,
                rdv: {
                    startDate: new Date(values.date.getTime() - values.date.getTimezoneOffset() * 60000).toISOString().slice(0, 10),
                    startTime: new Date(values.time.getTime() - values.time.getTimezoneOffset() * 60000).toISOString().slice(11, 16),
                },
            };

            return await apiParticulierService.requestRdv(flowId, auditAndRdv);
        } catch (error) {
            console.log('postAuditAndRdv Data : ' + error);
            throw error;
        }
    };

    const validerRdv = async (values: RDV) => {
        try {
            const template = storageService.getAudit();

            setLoading(true);
            await apiParticulierService.identify(agentCode, agentPassword);
            // update audit data
            // send audit to icoll if not already done
            if (!template.auditSent) {
                await apiParticulierService.updateAuditDataService();
                await sendAudit();
                // Mise à jour localstorage pour indiquer que l'audit est envoyé
                template.auditSent = true;
                storageService.setAudit(template);
            }
            // request rdv
            const response = await requestRdv(values);

            Swal.close();

            push(ROUTE_AUDIT_RDV_SIGNATURE + '?token=' + response.participantAccessToken, response.data);
        } catch (e) {
            console.error(e);
            AlertSwal.fire({
                title: 'Erreur de connexion',
                text: apiParticulierService.ERROR_INDISPONIBLE,
                icon: 'error',
                width: 550,
                confirmButtonText: 'Fermer',
                focusConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik onSubmit={() => {}} initialValues={initialValues} validateOnMount={true} validationSchema={validationSchema}>
            {({ values, setValues, errors, touched, handleBlur, isValid }) => {
                return (
                    <div className="container">
                        <h1 className="main-title-mini">Programmer un rendez-vous</h1>

                        <div className="card card-orange illustration-bottom mb-4">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="declarer">Je soussigné</label>

                                        <input type="text" id="declarer" className="form-control" value={values.declarer} readOnly={true} />
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <label htmlFor="agent">Déclare demander à ce jour un rendez-vous à</label>

                                        <input type="text" id="agent" className="form-control" value={values.agent} readOnly={true} />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-12">
                                        <p className="mb-0 pave-dsaumon">
                                            afin de nous présenter des solutions commerciales me permettant de réaliser des économies d'énergies dans mon foyer
                                        </p>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-auto">
                                        <div className="d-flex align-items-center">
                                            <label className="mb-0 me-3">Je souhaite un rendez-vous</label>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="rendezVousNow"
                                                    id="now"
                                                    value={values.rendezVousNow}
                                                    checked={values.rendezVousNow === 'now'}
                                                    onChange={() => {
                                                        setValues({
                                                            ...values,
                                                            rendezVousNow: 'now',
                                                            authorized: 'true',
                                                            date: new Date(),
                                                            time: getRoundedDate(stepMinutes),
                                                        });
                                                    }}
                                                />
                                                <label className={'form-check-label'} htmlFor="now">
                                                    Maintenant
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-auto">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="rendezVousNow"
                                                id="later"
                                                value={values.rendezVousNow}
                                                checked={values.rendezVousNow === 'later'}
                                                onChange={() => {
                                                    setValues({
                                                        ...values,
                                                        rendezVousNow: 'later',
                                                        authorized: '',
                                                        date: new Date(),
                                                        time: getRoundedDate(stepMinutes),
                                                    });
                                                }}
                                            />
                                            <label className={'form-check-label'} htmlFor="later">
                                                Ultérieurement
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {values.rendezVousNow === 'later' && (
                                    <div className="row mb-3">
                                        <div className="col-12 col-sm-6 col-lg-3">
                                            <label htmlFor="datepicker">
                                                Sélectionnez la date<sup>*</sup>
                                            </label>
                                            <label className={'w-100'}>
                                                <div className="input-group">
                                                    <DatePicker
                                                        className="form-control"
                                                        id="datepicker"
                                                        locale={fr}
                                                        minDate={new Date()}
                                                        disabledKeyboardNavigation
                                                        placeholderText="10/05/2021"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={values?.date}
                                                        closeOnScroll={true}
                                                        onChange={(date: Date) => {
                                                            setValues({
                                                                ...values,
                                                                date: date,
                                                            });
                                                        }}
                                                    />
                                                    <div className="input-group-text datepicker">&nbsp;</div>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-3">
                                            <label htmlFor="timepicker">
                                                Sélectionnez l'heure<sup>*</sup>
                                            </label>
                                            <label className={'w-100'}>
                                                <div className="input-group">
                                                    <DatePicker
                                                        className="form-control"
                                                        id="timepicker"
                                                        locale={fr}
                                                        minDate={new Date()}
                                                        disabledKeyboardNavigation
                                                        placeholderText="12H00"
                                                        dateFormat="HH:mm"
                                                        selected={values?.time}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeFormat="HH:mm"
                                                        timeIntervals={stepMinutes}
                                                        timeCaption="Horaire"
                                                        closeOnScroll={true}
                                                        onChange={(time: Date) => {
                                                            setValues({
                                                                ...values,
                                                                time: time,
                                                            });
                                                        }}
                                                    />
                                                    <div className="input-group-text timepicker">&nbsp;</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                )}

                                {values.rendezVousNow === 'later' && (
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <div className="form-check form-switch custom-switch justify-content-start">
                                                <CheckboxService
                                                    handleCheckbox={authorized}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'authorized'}
                                                    propName={values.authorized}
                                                    title={"J’autorise un opérateur de la société Sogys à m'appeler pour confirmer mon rendez-vous"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                                        <div className="row">
                                            <div className="col-12 col-md-6 position-relative">
                                                <label htmlFor="signedAt">Fait le</label>
                                                <input
                                                    type="date"
                                                    className={
                                                        'form-control' +
                                                        (values.signedAt ? ' filled' : '') +
                                                        (touched.signedAt && errors.signedAt ? ' invalid' : '') +
                                                        (!values.signedAt ? ' required' : '')
                                                    }
                                                    id="signedAt"
                                                    value={values.signedAt}
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            signedAt: event.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label htmlFor="place">À</label>
                                                <input
                                                    type="text"
                                                    id="place"
                                                    name="place"
                                                    className={
                                                        'form-control' +
                                                        (values.place ? ' filled' : '') +
                                                        (touched.place && errors.place ? ' invalid' : '') +
                                                        (!values.place ? ' required' : '')
                                                    }
                                                    value={values.place}
                                                    onBlur={handleBlur}
                                                    placeholder="Paris"
                                                    onChange={(event: any) => {
                                                        setValues({
                                                            ...values,
                                                            place: event.target.value,
                                                        });
                                                    }}
                                                />
                                                {touched.place && errors.place && <div className="invalid-feedback">{errors.place}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="navigation text-center">
                            <button className={'btn btn-secondary maxi-min-width mx-2 mb-3'} onClick={() => push(ROUTE_SIM_SYNTHESE)}>
                                Retour à la synthèse
                            </button>
                            <button className="btn btn-primary mx-2 px-5 mb-3" type="button" disabled={loading} onClick={(e) => validerRdv(values)}>
                                <span style={loading ? { opacity: '0' } : { opacity: '1' }}>Valider le rendez-vous</span>
                                {loading && (
                                    <div className="login-loader">
                                        <IconLoader width={38} height={38} />
                                    </div>
                                )}
                            </button>

                            {loading && (
                                <div
                                    style={{
                                        width: '100vw',
                                        height: '100vh',
                                        background: 'rgba(0,0,0,0.25)',
                                        zIndex: 1000,
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                    }}
                                >
                                    &nbsp;
                                </div>
                            )}
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default RendezVous;
