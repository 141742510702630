import React, { useMemo } from 'react';
import { HistoryItem } from '../../../services/tools/konvaTools';
import { Group, Rect, Text, Transformer } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';

type TextModuleProps = {
    item: HistoryItem;
    index: number;
    setSelectedElementIndex: (value: React.SetStateAction<number | null>) => void;
    handleDragMove: (e: KonvaEventObject<DragEvent>, index: number) => void;
    groupRefs: React.MutableRefObject<(Konva.Group | null)[]>;
    trRef: React.RefObject<Konva.Transformer>;
    fontSize: number;
    selectedElementIndex: number;
};

export const TextModule: React.FC<TextModuleProps> = ({
    item,
    index,
    setSelectedElementIndex,
    handleDragMove,
    groupRefs,
    trRef,
    fontSize,
    selectedElementIndex,
}) => {
    const padding = 5;

    const { rectWidth, rectHeight } = useMemo(() => {
        const tempTextNode = new Konva.Text({
            text: item.message,
            fontSize,
            fontFamily: 'Arial, sans-serif',
        });

        const textWidth = tempTextNode.width();

        const rectWidth = textWidth + 2 * padding;
        const rectHeight = fontSize + 2 * padding;
        return { rectWidth, rectHeight };
    }, [item.message]);

    return (
        <>
            <Group
                draggable
                onClick={() => {
                    if (!item.deleted) {
                        setSelectedElementIndex(index);
                    }
                }}
                onTap={() => {
                    if (!item.deleted) {
                        setSelectedElementIndex(index);
                    }
                }}
                ref={(ref) => (groupRefs.current[index] = ref)}
                onDragMove={(e) => handleDragMove(e, index)}
                x={item.position?.x}
                y={item.position?.y}
            >
                <Rect x={50} y={50} width={rectWidth} height={rectHeight} fill="white" />
                <Text
                    text={item.message}
                    fontSize={fontSize}
                    width={rectWidth}
                    height={fontSize}
                    x={50}
                    y={55} // to include offset
                    fill="black"
                    verticalAlign="center"
                    align="center"
                />
            </Group>
            {selectedElementIndex === index && (
                <Transformer
                    ref={trRef}
                    nodes={[groupRefs.current[index]!]}
                    anchorSize={10}
                    rotateEnabled
                    enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                />
            )}
        </>
    );
};
