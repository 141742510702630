import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import './signature.scss';
import { ROUTE_AUDIT, ROUTE_AUDIT_AL_FINALISATION } from '../../routing/paths';

const Signature: React.FC = () => {
    const { push } = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const [lastPage, setLastPage] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        renderIframe();
        // eslint-disable-next-line
    }, [isLoaded]);

    const agentMail = Cookies.getJSON('Auth').email;
    const agentPhone = Cookies.getJSON('Auth').tel;

    // Retours event iFrame
    const onDisplayEventMessageFn = (event: any) => {
        if (event) {
            if (event.action === 'SIGN') {
                window.scrollTo(0, 0);
            }

            if (event.page === 'attachments') {
                setLastPage(true);
                window.scrollTo(0, 0);
            }

            // Cliquer sur "refuser" ou "revenir plus tard"
            if (event.type === 'exitEvent') {
                push(ROUTE_AUDIT);
            }

            // Le dossier est complet
            if (event.action === 'FINALIZE') {
                push(ROUTE_AUDIT_AL_FINALISATION);
            }
        }
    };

    const renderIframe = () => {
        if (!isLoaded) {
            // @ts-ignore
            new NthSignbook({
                iframeSelectorId: 'signbook',
                url: `${process.env.REACT_APP_EKEYNOX}/contract/signbook/v3/#/signature-page?token=${token}&renderMode=pretty`,
                token,
                pageChanged: onDisplayEventMessageFn,
                onUserEventMessage: onDisplayEventMessageFn,
                onExitEventMessage: onDisplayEventMessageFn,
                onDisplayEventMessage: onDisplayEventMessageFn,
            });
        }
    };

    return (
        <div className="container">
            {!lastPage ? (
                <>
                    <h1 className="main-title-mini">Avant dernière étape</h1>
                    <p>
                        Félicitation vous êtes à l’avant dernière étape. Veuillez cliquer sur chacun des onglets ci dessous afin de visualiser tous les
                        documents, valider les termes et insérer le code secret reçu par sms dans le champs « Code Secret» en pied de page. Vous pourrez valider
                        votre dossier. Apres validation de votre dossier un écran s’affichera vous permettant de signer aussi manuellement.
                    </p>

                    <p>
                        Nos équipes restent à votre disposition <strong>7 jours sur 7</strong> de <strong>8 à 22h</strong> au&nbsp;
                        <a href={`tel:${agentPhone}`}>{agentPhone}</a> ou par email à <a href={`mailto:${agentMail}`}>{agentMail}</a>.
                    </p>
                </>
            ) : (
                <>
                    <h1 className="main-title-mini">Dernière étape</h1>
                    <p>Bienvenue sur l’espace de chargement des documents nécessaires à finaliser votre dossier.</p>
                    <p>
                        Vous pourrez soit charger un fichier PDF, soit une ou plusieurs photos au format JPEG ou PNG. Une fois les fichiers insérés veuillez
                        cliquer sur l’envoi du document avant de passer au suivant. Lorsque tous les documents auront été envoyés cliquez sur « Soumettre le
                        dossier ». Prenez soin d’envoyer des fichiers bien cadrés et lisibles. Cette dernière étape terminée vous recevrez un email de
                        confirmation ainsi qu’une copie des documents.
                    </p>
                    <p>
                        Selon le Règlement Européen (UE) 2016/679l, règlement général sur la protection des données "RGPD", les documents transmis seront
                        automatiquement supprimés de nos bases de données lorsque le dossier aura été traité par Action Logement.
                    </p>
                    <p>
                        Nos équipes restent à votre disposition <strong>7 jours sur 7</strong> de <strong>8 à 22h</strong> au{' '}
                        <a href={`tel:${agentPhone}`}>{agentPhone}</a> ou par email à <a href={`mailto:${agentMail}`}>{agentMail}</a>.
                    </p>
                </>
            )}
            <iframe
                id="signbook"
                title="signbook"
                src={`${process.env.REACT_APP_EKEYNOX}/contract/signbook/v3/#/signature-page?token=${token}&renderMode=pretty`}
                scrolling="no"
                width="100%"
                allow="microphone; camera"
                onLoad={() => setIsLoaded(true)}
            />
        </div>
    );
};

export default Signature;
