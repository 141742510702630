import React from 'react';
import * as storageService from '../../services/localStorageService';

// Style
import './HeaderBanner.scss';

const HeaderBanner: React.FC = () => {
    const displayAgent = () => {
        const agent = storageService.getLocalStorageAgent();
        if (!agent) return '';

        return agent.prenom_agent + ' ' + agent.nom_agent;
    };

    return (
        <div className="header-banner mb-5">
            <div className="container">
                <p>
                    Bienvenue dans votre espace <strong>{displayAgent()}</strong>
                </p>
            </div>
        </div>
    );
};

export default HeaderBanner;
