import React from 'react';
import { FormValues, HistoryDP5Props } from '../../PhotovoltaiqueEnums';
import { TextElement } from '../../../../../components/Text/Text';
import { Title } from '../../../../../components/Title/Title';
import { useFormikContext } from 'formik';

// Icon
import { ReactComponent as IconPhotovoltaique } from '../../../../../assets/icons/konva/icon-photovoltaique.svg';
import { ReactComponent as IconDelete } from '../../../../../assets/icons/konva/icon-trash.svg';
import { ReactComponent as IconDraw } from '../../../../../assets/icons/konva/icon-draw.svg';

// Style
import '../history/HistoryMap.scss';

const HistoryDP5: React.FC<HistoryDP5Props> = ({ setSelectedElementIndex, canDraw, openModal, disabled }) => {
    // const [modalInfo, setModalInfo] = useState<ModalInfoProps>({ title: '', show: false, message: '' });
    const formikContext = useFormikContext<FormValues>();
    // Set specific element to disabled
    const removeHistoryById = (idToRemove: string) => {
        formikContext.setValues({
            ...formikContext.values,
            historyDP5: formikContext.values.historyDP5.map((item) => (item.id === idToRemove ? { ...item, deleted: true } : item)),
        });
        setSelectedElementIndex(null);
    };

    // Display icon according to type
    const iconToDisplay = (type: string, color?: string) => {
        switch (type) {
            // case 'text':
            //     return (
            //         <TextElement className="history-icon">
            //             <IconText fill="white" />
            //         </TextElement>
            //     );

            // case 'observation':
            //     return (
            //         <TextElement className="history-icon">
            //             <IconObservation fill="white" />
            //         </TextElement>
            //     );

            case 'draw':
                return (
                    <TextElement className="history-icon" style={{ background: color }}>
                        <IconDraw fill="white" />
                    </TextElement>
                );

            // case 'arrow':
            //     return (
            //         <TextElement className="history-icon" style={{ background: color }}>
            //             <IconArrow fill="white" height={16} />
            //         </TextElement>
            //     );

            // case 'velux':
            //     return (
            //         <TextElement className="history-icon" style={{ background: color }}>
            //             <IconVelux fill="white" height={16} />
            //         </TextElement>
            //     );

            // case 'chimney':
            //     return (
            //         <TextElement className="history-icon" style={{ background: color }}>
            //             <IconChimney fill="white" height={16} />
            //         </TextElement>
            //     );

            // case 'dormer':
            //     return (
            //         <TextElement className="history-icon" style={{ background: color }}>
            //             <IconDormer fill="white" height={16} />
            //         </TextElement>
            //     );

            // case 'circle':
            //     return (
            //         <TextElement className="history-icon" style={{ background: color }}>
            //             <IconCircle fill="white" height={16} />
            //         </TextElement>
            //     );

            case 'photovoltaique':
                return (
                    <TextElement className="history-icon">
                        <IconPhotovoltaique fill="white" />
                    </TextElement>
                );

            default:
                return null;
        }
    };

    // Check all elements deleted in formikContext.values.historyDP5
    const allElementsDeleted = formikContext.values.historyDP5.every((item) => item.deleted);

    // Free forms in dropdown
    const componentsDropdown: React.ReactNode[] = [
        <button key="0" type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('circle')}>
            Cercle
        </button>,
        <button key="1" type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('chimney')}>
            Cheminée
        </button>,
        <button key="2" type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('dormer')}>
            Chien assis
        </button>,
        <button key="3" type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('arrow')}>
            Trait/flèche
        </button>,
        <button key="4" type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('panel')}>
            Panneau photovoltaïque
        </button>,
        <button key="5" type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('observation')}>
            Point d&apos;observation
        </button>,
        <button key="6" type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('text')}>
            Texte
        </button>,
        <button key="7" type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('velux')}>
            Velux
        </button>,
        <button key="8" type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('draw')}>
            Zone
        </button>,
    ];

    return (
        <>
            <div className="history history--dp5">
                <div className="history-content" style={{ pointerEvents: disabled ? 'none' : 'initial' }}>
                    <div className="history-title">
                        <Title as="h2" className="container-title justify-start">
                            Panneaux
                        </Title>
                        <div className="history-dropdown text-end">
                            <button type="button" className="btn btn-dropdown btn-md" onClick={() => openModal('photovoltaique')} disabled={disabled}>
                                Ajout panneau
                            </button>
                            {/* <Dropdown label="Ajout composant" items={componentsDropdown} disabled={canDraw} /> */}
                        </div>
                    </div>
                    <div className="history-list">
                        {formikContext.values.historyDP5.length === 0 || allElementsDeleted ? (
                            <TextElement as="p" className="no-component text-center mb-0">
                                Aucun panneau ajouté
                            </TextElement>
                        ) : (
                            <ul className="history-list-content">
                                {formikContext.values.historyDP5.map((history, index) => {
                                    // Remove element from history
                                    if (history.deleted) {
                                        return null;
                                    }

                                    return (
                                        <li key={index}>
                                            <TextElement as="p">
                                                {iconToDisplay(history.type, history.color)} <span className="text-overflow">{history.message}</span>
                                            </TextElement>
                                            <button type="button" className="btn btn-remove" onClick={() => removeHistoryById(history.id)} disabled={disabled}>
                                                <IconDelete fill="#cbd7ef" />
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HistoryDP5;
