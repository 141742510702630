import React, { useMemo, useState } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Circle, Group, Line, Text } from 'react-konva';
import Konva from 'konva';
import { Dot, HistoryItem, calculateCentroid } from '../../../services/tools/konvaTools';
import { FormValues } from '../../../pages/pre-visite/photovoltaique/PhotovoltaiqueEnums';

type DrawModuleProps = {
    item: HistoryItem;
    index: number;
    canDraw: boolean;
    fontSize: number;
    values: FormValues;
    setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void;
};

export const DrawModule: React.FC<DrawModuleProps> = ({ item, canDraw, index, fontSize, setValues, values }) => {
    const [showCircle, setShowCircle] = useState<boolean>(false);
    const centroid = item.drawing?.dots ? calculateCentroid(item.drawing.dots) : { x: 0, y: 0 };
    const fillColor = item.opacity ? item.color : item.color + '50';
    const textColor = item.opacity ? '#000' : item.color;

    const { textWidth, textHeight } = useMemo(() => {
        const tempTextNode = new Konva.Text({
            text: item.message,
            fontSize: fontSize,
            fontFamily: 'Arial, sans-serif',
        });

        const textWidth = tempTextNode.width();
        const textHeight = tempTextNode.height();

        return { textWidth, textHeight };
    }, [item.message]);

    const onCircleMove = (e: KonvaEventObject<MouseEvent>, historyIndex: number, dotIndex: number) => {
        const target = e.target;
        const updatedDot: Dot = { x: target.x(), y: target.y(), isHovered: false };

        const updatedHistory = values.history.map((item, index) => {
            if (index === historyIndex) {
                const updatedItem = { ...item };

                if (updatedItem.drawing && updatedItem.drawing.dots) {
                    updatedItem.drawing.dots[dotIndex] = updatedDot;
                }

                return updatedItem;
            }

            return item;
        });

        setValues({
            ...values,
            history: updatedHistory,
        });
    };

    // ATTENTION ça ne fonctionne pas donc si la parcelle est bougée, les images dp seront faussées

    // const handleDragEnd = (e: KonvaEventObject<DragEvent>, index: number) => {
    //     const group = e.target;
    //     const dx = group.x();
    //     const dy = group.y();

    //     const updatedHistory = values.history.map((item, idx) => {
    //         if (idx === index) {
    //             const updatedItem = { ...item };

    //             if (updatedItem.drawing && updatedItem.drawing.dots) {
    //                 updatedItem.drawing.dots = updatedItem.drawing.dots.map((dot) => ({
    //                     ...dot,
    //                     x: dot.x + dx,
    //                     y: dot.y + dy,
    //                 }));
    //             }

    //             return updatedItem;
    //         }

    //         return item;
    //     });

    //     setValues({
    //         ...values,
    //         history: updatedHistory,
    //     });
    // };

    return (
        <>
            <Group
                draggable={!canDraw && item.drawing?.dots.find((dot) => dot.isHovered !== false) === undefined}
                // onDragEnd={(e) => handleDragEnd(e, index)}
                onMouseEnter={() => setShowCircle(true)}
                onMouseLeave={() => setShowCircle(false)}
            >
                {item.drawing?.dots && item.drawing?.dots?.length > 1 && (
                    <Line
                        points={item.drawing.dots.flatMap((dot) => [dot.x, dot.y])}
                        stroke={item.color}
                        strokeWidth={showCircle || canDraw ? 2 : 0}
                        fill={fillColor}
                        closed={item.drawing.completed}
                        hitStrokeWidth={20}
                    />
                )}
                {(showCircle || canDraw) &&
                    item.drawing?.dots.map((dot, dotIndex) => {
                        return (
                            <Circle
                                key={dotIndex}
                                x={dot.x}
                                y={dot.y}
                                radius={2}
                                fill={item.color}
                                draggable={!canDraw}
                                onDragMove={(e) => onCircleMove(e, index, dotIndex)}
                                stroke={item.color}
                                strokeWidth={5}
                            />
                        );
                    })}
                {item.drawing?.completed && item.type === 'draw' && item.message !== 'Parcelle' && (
                    <Text
                        text={item.message}
                        fill={textColor}
                        fontSize={fontSize}
                        x={centroid.x}
                        y={centroid.y}
                        align="center"
                        verticalAlign="middle"
                        offsetX={textWidth / 2}
                        offsetY={textHeight / 2}
                    />
                )}
            </Group>
        </>
    );
};
