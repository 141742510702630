import React from 'react';
import * as storageService from '../../../services/localStorageService';

import Cookies from 'js-cookie';
import '../../finalisation/finalisation.scss';

import MaPrimeRenovLogo from '../../../assets/images/logo-maprimerenov.jpg';
import LogoSogys from '../../../assets/images/logo-sogys.svg';

const FinalisationRAC: React.FC = () => {
    const clientNom = storageService.getAudit().step1.userLastname.value;
    const clientPrenom = storageService.getAudit().step1.userFirstname.value;

    const companyLogo = Cookies.getJSON('Auth').companyLogo;
    const companyName = Cookies.getJSON('Auth').companyName;

    return (
        <div className="container">
            <div className="text-center my-5">
                <img src={MaPrimeRenovLogo} alt="MaPrimeRenov'" height={140} />
            </div>

            <p>
                <strong>
                    Félicitations {clientPrenom} {clientNom}, votre commande a bien été prise en compte.
                </strong>
            </p>

            <p>Nos équipes ne manquerons pas de revenir vers vous au fur et à mesure de l'évolution de votre commande.</p>

            <p>
                Pour toute question relative à votre dossier vous pouvez contacter nos services à l'adresse mail suivante : &nbsp;
                <a href="mailto:contact@sogys.fr">contact@sogys.fr</a>
            </p>

            <p>À très bientôt</p>

            <p>L'équipe Sogys</p>

            <div className="signature-logos mt-5">
                <div className="main-logo">
                    <img src={LogoSogys} alt="Sogys" aira-hidden="true" />
                </div>

                <img src={companyLogo ? companyLogo : LogoSogys} alt={companyName ? companyName : ''} aria-hidden="true" />
            </div>
        </div>
    );
};

export default FinalisationRAC;
