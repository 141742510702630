import React, { useEffect, useRef } from 'react';

import './signature.scss';
import { ROUTE_AUDIT_RDV, ROUTE_SIM_RECOMMANDATION } from '../../routing/paths';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import * as apiParticulierService from '../../services/apiParticulierService';
import * as localStorageService from '../../services/localStorageService';

const RdvSignature: React.FC = () => {
    const { push } = useHistory();
    const signbookRef = useRef<any>(null);
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const companyLogo = Cookies.getJSON('Auth').companyLogo;

    if (token === null || token === undefined || token === '') {
        console.error('Something went WRONG ! no token');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const initializeSignbook = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-new, no-undef
            signbookRef.current = new NthSignbook({
                iframeSelectorId: 'signbook',
                url: `${process.env.REACT_APP_EKEYNOX}/contract/signbook/v3/#/signature-page`,
                options: {
                    renderMode: 'pretty',
                },
                token,
                onExitEventMessage: onExitEventMessageFn,
                onErrorEventMessage: onErrorEventMessageFn,
                onClientFileEventMessage: onClientFileEventMessage,
            });
        };

        const destroySignbook = () => {
            if (signbookRef.current) {
                signbookRef.current.destroy();
            }
        };

        initializeSignbook();

        // Clean up event listeners when the component unmounts
        return () => {
            destroySignbook();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Retours event iFrame
    function onExitEventMessageFn(event: any) {
        console.log(event);
        push(ROUTE_AUDIT_RDV);
    }

    const onClientFileEventMessage = async (event: any) => {
        // Event coming from eKeynox SaaS with data concerning the state of the client file
        if (
            (event.changedState && event.changedState.from === 'PENDING' && (event.changedState.to === 'WAITING' || event.changedState.to === 'ACCEPTED')) ||
            event.participantState === 'WAITING'
        ) {
            const flowId = localStorage.getItem('flowId');
            if (!flowId) {
                throw new Error('Impossible de valider le rdv, parcours utilisateur inexistant');
            }
            const appointement = await apiParticulierService.validateRdv(flowId);
            localStorageService.setRecoAppointment(appointement);
            push(ROUTE_SIM_RECOMMANDATION);
        } else {
            if (event.state !== 'PENDING') {
                console.log('State:', event.state);
            }
        }
    };

    function onErrorEventMessageFn(event: any) {
        // Errors detected by the SignBook (server errors, invalid state, unsupported browser, etc.)
        if (event.cause === 'REJECTED_STATE' || event.cause === 'ACCEPTED_STATE' || event.cause === 'SUSPENDED_STATE' || event.cause === 'WAITING_STATE') {
            // Do not deal with these "errors", this is just for backwards compatibility purpose...
            return;
        }

        console.error('Erreur event:', event.cause);
    }

    return (
        <div className="container py-5">
            <div className="container">
                <div className="signature-logos mt-5">
                    <div className="main-logo">
                        <img src={companyLogo} alt="Logicoll" aira-hidden="true" />
                    </div>
                </div>{' '}
                <br />
                <br />
                <h1>Votre demande de rendez-vous</h1>
                <p>
                    Bienvenue sur l’espace de demande de rendez-vous, nécessaire à la présentation d’offres commerciales. Vous recevrez d’ici quelques secondes
                    un code par sms qu’il vous faudra renseigner dans la cellule « Code secret »
                </p>
                <p>
                    Nos équipes se tiennent à votre disposition, 7 jours sur 7 de 8h à 22h au <a href="tel:0826046046">0826 046 046</a> ou par email à{' '}
                    <a href="mailto:contact@sogys.fr">contact@sogys.fr</a>
                </p>
            </div>

            <iframe id="signbook" title="signbook" width="99%" allow="microphone; camera" style={{ border: '0', overflow: 'hidden' }} />
        </div>
    );
};

export default RdvSignature;
