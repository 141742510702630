import React from 'react';
import { PolymorphicComponentProps } from '../../services/componentsHelpers/polymorpgic';
import { FormikValues, FormikState, FormikHelpers, FieldInputProps, FormikHandlers } from 'formik';
import { AppFormLabel } from './AppFormLabel';
/*
 Custom Formik input field
*/

type Props = {
    type?: string;
    disabled?: boolean;
    labelName?: string;
    labelVisible?: boolean;
    customClass?: string;
    isRequired?: boolean;
    inline?: boolean;
    infoText?: string | JSX.Element | undefined;
    field: FieldInputProps<FormikValues>;
    form: FormikState<FormikValues> & FormikHelpers<FormikValues> & FormikHandlers;
    placeholder?: string | undefined;
    disableOnchange: boolean;
    onCustomChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    withReplace?: { toReplace: string; replaced: string };
    customLabelClass?: string;
};

type AppFormFieldProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>;

const AppFormField = <C extends React.ElementType = 'input'>({
    type = 'text',
    isRequired,
    labelName,
    labelVisible = false,
    customClass = '',
    as,
    inline = true,
    infoText = undefined,
    field: { name, onChange, value },
    form: { errors, touched, setFieldValue, handleBlur },
    disableOnchange = false,
    onCustomChange,
    withReplace,
    disabled,
    customLabelClass,
    ...other
}: AppFormFieldProps<C>): JSX.Element => {
    const hasError = errors && touched && errors[name] && touched[name];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!disableOnchange) {
            if (withReplace) {
                const result = e.target.value.replace(withReplace.toReplace, withReplace.replaced);
                setFieldValue(name, result);
            } else {
                onChange(e);
            }
        }
        // if custom on change event
        if (onCustomChange) {
            onCustomChange(e);
        }
    };

    const Component = as || 'input';

    return (
        <div className={`${inline ? 'form-row' : 'form-group'} ${customClass}`}>
            <AppFormLabel
                htmlFor={name}
                labelTitle={labelName}
                isRequired={isRequired}
                hidden={labelVisible}
                infoText={infoText}
                customClass={inline ? 'form-label' : customLabelClass}
                inline={inline}
            />
            <Component
                type={type}
                onChange={handleChange}
                value={(value as unknown as string | number) ?? ''}
                name={name}
                onBlur={handleBlur}
                id={name}
                disabled={disabled}
                autoComplete="off"
                onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                {...other}
            />

            {!!hasError && <span className="field__error">{errors[name] as unknown as string}</span>}
        </div>
    );
};

export default AppFormField;
