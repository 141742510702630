import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import './Audit.scss';

import Template from '../../assets/json/template-audit.json';
import TemplateSimulator from '../../assets/json/template-simulator.json';
import TemplatePreVisit from '../../assets/json/template-previsite.json';
import * as storageService from '../../services/localStorageService';

import { ReactComponent as IconClientInformations } from '../../assets/icons/icon-client-informations.svg';
import { ReactComponent as IconHomeInformations } from '../../assets/icons/icon-home-informations.svg';
import { ReactComponent as IconEnergySystemInformations } from '../../assets/icons/icon-energy-system-informations.svg';
import { ReactComponent as IconEnergyCostInformations } from '../../assets/icons/icon-energy-cost.svg';
import { ReactComponent as IconCollectionsReportsInformations } from '../../assets/icons/icon-collections-reports.svg';
import { ReactComponent as IconEnveloppe } from '../../assets/icons/icon-enveloppe.svg';
import { ReactComponent as IconOpening } from '../../assets/icons/icon-opening.svg';
import { ReactComponent as IconPhotos } from '../../assets/icons/icon-photos.svg';
import * as Yup from 'yup';
import { Formik } from 'formik';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import {
    ROUTE_AUDIT_AL,
    ROUTE_AUDIT_INFO_CLIENT,
    ROUTE_AUDIT_INFO_DEPENSES_ENERGETIQUE,
    ROUTE_AUDIT_INFO_EVELOPPE,
    ROUTE_AUDIT_INFO_HABITATION,
    ROUTE_AUDIT_INFO_RECUEIL_CONSTAT,
    ROUTE_AUDIT_INFO_SYSTEMES_ENERGETIQUES,
    ROUTE_AUDIT_OUVRANT,
    ROUTE_AUDIT_PHOTOS,
    ROUTE_AUDIT_RAC,
    ROUTE_SIM_SYNTHESE,
} from '../../routing/paths';
import { FullFlowStatus } from '../../services/apiFlowService';

const Audit: React.FC = () => {
    const { push } = useHistory();
    const history: any = useHistory();
    const AlertSwal = withReactContent(Swal);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [currentStep, setCurrentStep] = useState(storageService.checkLocalStorageCurrentStep() ? storageService.getAuditCurrentStep() : 1);

    // setup de la campagne pour les écrans suivants.
    const comeFromAL = history?.location?.state?.from && history?.location?.state?.from === 'action-logement';
    const comeFromRAC = history?.location?.state?.from && history?.location?.state?.from === 'reste-a-charge';
    const campagne: storageService.Campagne = comeFromAL ? 'action_logement' : comeFromRAC ? 'reste_a_charge' : undefined;

    const storageOK = storageService.checkLocalStorageCurrentAgent();
    if (!storageOK) {
        // si pas encore de storage valide, on le créé.
        // Injection du gabarit JSON dans le local storage
        storageService.setAudit(Template);
        // Injection de l'étape de départ
        setCurrentStep(1);

        // Injection du gabarit JSON simulator si on est en ni AL ni RAC
        if (!comeFromAL && !comeFromRAC) storageService.setSim(TemplateSimulator);
        if (!comeFromAL && !comeFromRAC) storageService.setPrevisit(TemplatePreVisit);
    }
    // set la campagne qui peut être vide  !
    storageService.setAuditCampagne(campagne);

    // set le template audit-simulator ==> `codeAgent-SIM`
    // Set audit-simulator audit
    const agentCode: string = Cookies.getJSON('Auth').agentCode;
    const auditSimulator: string | null = localStorage.getItem(`${agentCode}-SIM`);
    const auditPreVisite: string | null = localStorage.getItem(`${agentCode}-PV`);

    if (!auditSimulator) {
        storageService.setSim(TemplateSimulator);
    }

    if (!auditPreVisite) {
        storageService.setPrevisit(TemplatePreVisit);
    }

    // Changer le titre en fonction de la campagne
    if (storageService.getAuditCampagne() === 'action_logement') {
        document.title = 'SOGYS.APP – AL';
    } else if (storageService.getAuditCampagne() === 'reste_a_charge') {
        document.title = 'SOGYS.APP – RAC';
    } else {
        document.title = 'SOGYS.APP – AUDIT';
    }

    // Vérifier si heaterType 1 & 2 sont du Gaz
    const gasChecker = () => {
        const audit = storageService.getAudit();
        if (audit.step3.heaterType.value === '4') {
            if (audit.step8.energyCostsGasInvoice1.value !== '') {
                return true;
            }
            return false;
        }

        if (audit.step3.heaterType2.value === '4') {
            if (audit.step8.energyCostsGasInvoice1.value !== '') {
                return true;
            }
            return false;
        }
        return true;
    };

    const audit = storageService.getAudit(); // can be undefined

    const initialValues = {
        clientInformations: storageOK ? audit?.step1?.isValid : false,
        homeInformations: storageOK ? audit?.step2?.isValid : false,
        energySystemInformations: storageOK
            ? audit?.step3?.isValid
            : false &&
              audit.step8.buildingPicturesheaterFeature.value !== '' &&
              audit.step8.buildingPicturesheaterGeneratorPower.value !== '' &&
              audit.step8.buildingPicturesenergyWater.value !== '',
        energyCostInformations: storageOK ? audit?.step4?.isValid : false && audit.step8.energyCostsElectricInvoice1.value !== '' && gasChecker(),
        collectionsReportsInformations: storageOK ? audit?.step5?.isValid : false,
        envelopeInformations: storageOK ? audit?.step6?.isValid : false,
        openings: storageOK
            ? audit?.step7?.isValid
            : false && audit.step8.buildingPictureDoor1.value !== '' && audit.step8.buildingPictureBuildingWindows1.value !== '',
        photos: storageOK ? audit?.step8?.isValid : false,
    };

    const validationSchema = Yup.object().shape({
        clientInformations: Yup.boolean().required(`1. Informations clients`).oneOf([true], `1. Informations clients`),
        homeInformations: Yup.boolean().required(`2. Informations générales de l'habitation`).oneOf([true], `2. Informations générales de l'habitation`),
        energySystemInformations: Yup.boolean()
            .required(`3. Caractéristiques des systèmes énergétiques`)
            .oneOf([true], `3. Caractéristiques des systèmes énergétiques`),
        energyCostInformations: Yup.boolean().required(`4. Dépenses énergétiques`).oneOf([true], `4. Dépenses énergétiques`),
        collectionsReportsInformations: Yup.boolean().required(`5. Recueil & constat`).oneOf([true], `5. Recueil & constat`),
        envelopeInformations: Yup.boolean().required(`6. Enveloppe`).oneOf([true], `6. Enveloppe`),
        openings: Yup.boolean().required(`7. Les ouvrants`).oneOf([true], `7. Les ouvrants`),
        photos: Yup.boolean().required(`8. Ajouter des photos de l'habitation`).oneOf([true], `8. Ajouter des photos de l'habitation`),
    });

    const currentFlowState = localStorage.getItem('flowState') as FullFlowStatus;

    return (
        <Formik onSubmit={() => {}} initialValues={initialValues} validateOnMount={true} validationSchema={validationSchema}>
            {({ values, errors }) => {
                return (
                    <div className="container">
                        <h1 className="main-title-mini mb-4">Audit énergétique</h1>
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-3 mb-3">
                                <Link to={ROUTE_AUDIT_INFO_CLIENT}>
                                    <div className={'card' + (values.clientInformations ? ' valid' : ' invalid')}>
                                        <div className="card-body">
                                            <div className="frame">
                                                <IconClientInformations fill="#ffffff" className="icon" />
                                            </div>
                                            <h2>
                                                1. Informations
                                                <br />
                                                client
                                            </h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 mb-3">
                                <Link to={ROUTE_AUDIT_INFO_HABITATION}>
                                    <div className={'card' + (currentStep < 2 ? ' disabled' : values.homeInformations ? ' valid' : ' invalid')}>
                                        <div className="card-body">
                                            <div className="frame">
                                                <IconHomeInformations fill="#ffffff" className="icon" />
                                            </div>
                                            <h2>
                                                2. Informations
                                                <br />
                                                générales de l'habitation
                                            </h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 mb-3">
                                <Link to={ROUTE_AUDIT_INFO_SYSTEMES_ENERGETIQUES}>
                                    <div className={'card' + (currentStep < 3 ? ' disabled' : values.energySystemInformations ? ' valid' : ' invalid')}>
                                        <div className="card-body">
                                            <div className="frame">
                                                <IconEnergySystemInformations fill="#ffffff" className="icon" />
                                            </div>
                                            <h2>
                                                3. Caractéristiques des
                                                <br />
                                                systèmes énergétiques
                                            </h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 mb-3">
                                <Link to={ROUTE_AUDIT_INFO_DEPENSES_ENERGETIQUE}>
                                    <div className={'card' + (currentStep < 4 ? ' disabled' : values.energyCostInformations ? ' valid' : ' invalid')}>
                                        <div className="card-body">
                                            <div className="frame">
                                                <IconEnergyCostInformations fill="#ffffff" className="icon" />
                                            </div>
                                            <h2>
                                                4. Dépenses
                                                <br />
                                                énergétiques
                                            </h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12 col-md-6 col-xl-3 mb-3">
                                <Link to={ROUTE_AUDIT_INFO_RECUEIL_CONSTAT}>
                                    <div className={'card' + (currentStep < 5 ? ' disabled' : values.collectionsReportsInformations ? ' valid' : ' invalid')}>
                                        <div className="card-body">
                                            <div className="frame">
                                                <IconCollectionsReportsInformations fill="#ffffff" className="icon" />
                                            </div>
                                            <h2>5. Recueil & constat</h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 mb-3">
                                <Link to={ROUTE_AUDIT_INFO_EVELOPPE}>
                                    <div className={'card' + (currentStep < 6 ? ' disabled' : values.envelopeInformations ? ' valid' : ' invalid')}>
                                        <div className="card-body">
                                            <div className="frame">
                                                <IconEnveloppe fill="#ffffff" className="icon" />
                                            </div>
                                            <h2>6. Enveloppe</h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3 mb-3">
                                <Link to={ROUTE_AUDIT_OUVRANT}>
                                    <div className={'card' + (currentStep < 7 ? ' disabled' : values.openings ? ' valid' : ' invalid')}>
                                        <div className="card-body">
                                            <div className="frame">
                                                <IconOpening fill="#ffffff" className="icon" />
                                            </div>
                                            <h2>7. Les ouvrants</h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3">
                                <Link to={ROUTE_AUDIT_PHOTOS}>
                                    <div className={'card' + (storageService.isAuditStepValid(8) ? ' photos-valid' : ' photos-invalid')}>
                                        <div className="card-body">
                                            <div className="frame">
                                                <IconPhotos fill="#ffffff" className="icon" />
                                            </div>
                                            <h2>
                                                8. Ajouter des photos de
                                                <br />
                                                l’habitation
                                            </h2>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className="navigation text-center">
                            {(currentFlowState === 'Created' ||
                                currentFlowState === 'AuditSent' ||
                                currentFlowState === 'RdvRequested' ||
                                currentFlowState === 'WithoutRdv') && (
                                <button
                                    type="button"
                                    className={'btn btn-primary btn-maxi'}
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        if (Object.entries(errors).length !== 0) {
                                            // Préparation liste d'étapes en erreur
                                            let errorsList: string[] = [];
                                            Object.keys(errors).forEach((step: any) => {
                                                // @ts-ignore
                                                errorsList = [...errorsList, `${errors[step]}`];
                                            });
                                            // Affichage message
                                            AlertSwal.fire({
                                                title: `Des étapes n’ont pas été validées`,
                                                html: `<p class="mb-0">Pour valider l’audit et la prise de rendez-vous, il est nécessaire que vous remplissiez correctement les étapes:<br/>
                                                <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span></p>`,
                                                cancelButtonText: 'Confirmer',
                                                showConfirmButton: false,
                                                showCancelButton: true,
                                                focusCancel: true,
                                                showClass: {
                                                    popup: 'errors',
                                                    backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                                },
                                                customClass: {
                                                    cancelButton: 'btn btn-primary maxi-min-width',
                                                },
                                            });
                                        } else {
                                            const campagne = storageService.getAuditCampagne();
                                            switch (campagne) {
                                                case 'action_logement':
                                                    push(`${ROUTE_AUDIT_AL}/recapitulatif`);
                                                    break;
                                                case 'reste_a_charge':
                                                    push(`${ROUTE_AUDIT_RAC}/recapitulatif`);
                                                    break;
                                                default:
                                                    push(ROUTE_SIM_SYNTHESE);
                                                    break;
                                            }
                                        }
                                    }}
                                >
                                    {storageService.getAuditCampagne() ? <>Finaliser mon dossier</> : <>Accéder à ma synthèse</>}
                                </button>
                            )}
                            {/* {auditSent && (
                                <a type="button" rel="noreferrer" href={process.env.REACT_APP_SRDV} target="_blank" className={'btn btn-primary btn-maxi'}>
                                    Signer numériquement l’audit
                                </a>
                            )} */}
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default Audit;
