import React, { useRef } from 'react';

// Style
import './ModaleProjectionFinanciere.scss';

// Icons
import { ReactComponent as IconClose } from '../../assets/icons/icon-close.svg';
import { currencyFormat, percentFormat } from '../../services/tools/TypeHelper';
import useOnClickOutside from '../../hook/useOnClickOutside';
import * as precoService from '../../services/calculs/applyPreconisation';

type TModalFooter = Array<{
    title: string;
    colspan?: number | null;
}>;

interface Props {
    topTitle?: string;
    closeTop?: boolean;
    title: string;
    close: () => void;
    content: precoService.ProjectionFinanciere;
}

const ModaleProjectionFinanciere: React.FC<Props> = ({ topTitle, title, close, closeTop, content }) => {
    const ref = useRef(null);

    const annuel = content?.projectionsAnnuelles ?? new Array<precoService.ProjectionFinanciereAnnuelle>();

    // est-ce qu'on affiche la colonne auto conso ?
    const showAutoconso = annuel.some((e) => {
        return e.autoconso !== 0;
    });
    // est-ce qu'on affiche la colonne autoConsoRatio
    const showAutoconsoRatio = showAutoconso;
    // est-ce qu'on affiche la colonne revente
    const showRevente = annuel.some((e) => {
        return e.revente !== 0;
    });

    //construit les titres de colonnes.
    const titles = new Array<string>();
    titles.push('Année');
    if (showAutoconsoRatio) titles.push('% Autoconso');
    if (showAutoconso) titles.push('Autoconso');
    if (showRevente) titles.push('Revente');
    titles.push('Economie (hors PV)');
    titles.push('Total');
    titles.push('Rendement');

    // construit les footers
    const contentFooter: TModalFooter = [
        {
            title: 'Sur 25 ans',
            colspan: titles.length - 3,
        },
        {
            title: currencyFormat(content.economieHorsPV.prix()),
        },
        {
            title: currencyFormat(content.economiePV.total + content.economieHorsPV.prix()),
        },
        {
            title: percentFormat(content.rendement),
        },
    ];

    const handleClickOutsite = () => {
        close();
    };

    useOnClickOutside(ref, handleClickOutsite);

    return (
        <div className="modal modal-custom modal-finance" tabIndex={0} role="dialog" aria-labelledby={title}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" ref={ref}>
                    {topTitle && <p className="modal-top-title">{topTitle}</p>}

                    {closeTop && (
                        <button type="button" className="btn btn-modal-close" onClick={handleClickOutsite}>
                            <IconClose fill="#FD5C21" />
                        </button>
                    )}
                    <div className="modal-header">
                        <h2 className="modal-title">{title}</h2>
                    </div>

                    <div className="modal-body">
                        <table className="table table-modal">
                            <thead>
                                <tr>
                                    {titles.map((title: string, index: number) => (
                                        <th key={index}>{title}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {annuel?.map((element, index: number) => (
                                    <tr key={index}>
                                        <td>{element.annee + 1}</td>
                                        {showAutoconsoRatio && <td>{percentFormat(element.tauxAutoconso)}</td>}
                                        {showAutoconso && <td>{currencyFormat(element.autoconso)}</td>}
                                        {showRevente && <td>{currencyFormat(element.revente)}</td>}
                                        <td>{currencyFormat(element.economieHorsPV)}</td>
                                        <td>{currencyFormat(element.total)}</td>
                                        <td>{percentFormat(element.rendement, 2)}</td>
                                    </tr>
                                ))}
                                <tr className="total">
                                    {contentFooter &&
                                        contentFooter.map((footer, index: number) => (
                                            <td colSpan={footer.colspan ? +footer.colspan : 0} key={index}>
                                                {footer.title}
                                            </td>
                                        ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModaleProjectionFinanciere;
