import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, BarChart, CartesianGrid, Bar, YAxis, XAxis, Tooltip, TooltipProps } from 'recharts';
import * as enedisCalcul from '../../services/enedis/enedis';
import * as enedisService from '../../services/enedisService';
import * as storageService from '../../services/localStorageService';
import { EnergyPriceProjection, getEnergyPriceProjection } from '../../services/apiParticulierService';
import { EnergyPrice, getPricesWithSubscription } from '../../services/calculs/bilanEnergieService';
import { currencyFormat, powerFormat } from '../../services/tools/TypeHelper';
import { BornePeriode, GraphData, getBorne } from '../../services/tools/graphHelper';

// Icon
import { ReactComponent as IconTriangle } from '../../assets/icons/icon-triangle.svg';
import { ReactComponent as IconProhibited } from '../../assets/icons/icon-prohibited.svg';
import { ReactComponent as LoaderSVG } from '../../assets/icons/loader.svg';

// SCSS
import '../../assets/style/custom-tooltip.scss';
import {} from '../../services/tools/TypeHelper';

const periodNames = ['Jours', 'Mois', 'Annees'] as const;
type Period = (typeof periodNames)[number];
const uniteNames = ['kWh', '€'] as const;
type Unite = (typeof uniteNames)[number];
type DataType = `${Period}${Unite}`;

//#region read me
//
// Pour le graphique on affiche un bar graph des données enedis.
//
// On affiche ensuite 3 jeux de données :
// il s'agit du même jeu de donénes : les énergies par pas d'une demi-heure sur 2 ans.
// mais représentées groupées par jours/mois/années.
// on représente respectivement des heures/jours/mois.
//
// pour des raisons de calculs et visualisation,
// lorsque les données enedis sont incomplètes, on enrichit les données de 0
// (exemple fin de l'année en cours, pour laquelle le client n'a pas encore de vraies données)
// ca a plusieurs avantage, mais pas mal d'inconvénients.
//
// pour éviter d'afficher des succession de jours remplis de 0, on créé des limites d'index :
// Les bornes : BornePeriode
// pour une période données, il s'agit du premier index et du dernier index qui contiennent des données utiles.
// Lorsqu'on change de période, on change aussi de bornes
//
//
//#endregion

export type EnedisGraphEntry = { puissanceW: number; energieKWh: number; label: string };

type Projection = {
    price: number;
    unitPrice: number;
    year: number;
};

type Projections = {
    conso: number;
    past: Projection;
    current: Projection;
    futur: Projection;
};

type ProjectionPerPeriod = { [key in Period]: Projections };

const CustomTooltip: React.FC<TooltipProps<string, string> & { selectedUnite: string }> = ({ active, payload, label, selectedUnite }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <div className="custom-tooltip-header">
                    <p className="custom-tooltip-label mb-0">{label}</p>
                </div>
                <div className="custom-tooltip-body">
                    <ul className="custom-tooltip-list">
                        {payload.map((element, key) => (
                            <li className="custom-tooltip-element mb-0" key={key}>{`${powerFormat(element.value)} ${selectedUnite}`}</li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const initialProjectionPerPeriod: ProjectionPerPeriod = {
    Annees: {
        conso: 0,
        past: { price: 0, unitPrice: 0, year: 0 },
        current: { price: 0, unitPrice: 0, year: 0 },
        futur: { price: 0, unitPrice: 0, year: 0 },
    },
    Mois: {
        conso: 0,
        past: { price: 0, unitPrice: 0, year: 0 },
        current: { price: 0, unitPrice: 0, year: 0 },
        futur: { price: 0, unitPrice: 0, year: 0 },
    },
    Jours: {
        conso: 0,
        past: { price: 0, unitPrice: 0, year: 0 },
        current: { price: 0, unitPrice: 0, year: 0 },
        futur: { price: 0, unitPrice: 0, year: 0 },
    },
};

const EnedisGraphic: React.FC = () => {
    // const SHOW_DEBUG = process.env.REACT_APP_MODE_DEBUG === 'true'; // apparaît que si on est en debug

    const [, setMesureDetaillee] = useState<enedisService.ConsultationMesuresDetailleesV3Output>();
    // LEs 3 jeux de données selon la période
    const [byDay, setByDay] = useState<Array<GraphData<EnedisGraphEntry>>>();
    const [byMonth, setByMonth] = useState<Array<GraphData<EnedisGraphEntry>>>();
    const [byYear, setByYear] = useState<Array<GraphData<EnedisGraphEntry>>>();
    // la période selectionnée
    const [selectedPeriod, setSelectedPeriod] = useState<Period>('Annees');

    // Les 3 bornes (min,max) pour chaque période
    const [borneByDay, setBorneByDay] = useState<BornePeriode>({ max: 0, min: 0 });
    const [borneByMonth, setBorneByMonth] = useState<BornePeriode>({ max: 0, min: 0 });
    const [borneByYear, setBorneByYear] = useState<BornePeriode>({ max: 0, min: 0 });
    // la borne selectionnée
    const [selectedBorne, setSelectedBorne] = useState<BornePeriode | undefined>(undefined);

    // l'index global (quelque soit le jeu de donnée)
    // il doit être compris entre selectedBorne.min et selectedBorne.max
    const [index, setIndex] = useState<number>(0);

    // Le type de données selectionnée, kWh ou €, MAIS TOUJOURS des kWh !
    const [selectedUnite] = useState<Unite>('kWh');

    // des couts d'electricité moyens, venant du BO selon l'abonnement du client ...
    // Pour afficher le cadre en bas, avec les projections de cout d'electricité.
    const [projectionPerPeriod, setProjectionPerPeriod] = useState<ProjectionPerPeriod>(initialProjectionPerPeriod);
    const [graphDataIndex, setGraphDataIndex] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<boolean>(false);

    //#region changement d'échelle de zoom sur les données,
    // par le bouton de selecteur, ou le bar graph

    const setIndexHelper = (targetBorne: BornePeriode, targetIndex: number | undefined): void => {
        let finalIndex;

        if (targetIndex === undefined) {
            //Si pas d'index cible, on décide d'afficher la borne max. et pourquoi pas ?!
            finalIndex = targetBorne.max;
        } else {
            finalIndex = targetIndex;
            if (targetIndex > targetBorne.max) finalIndex = targetBorne.max;
            if (targetIndex < targetBorne.min) finalIndex = targetBorne.min;
        }
        setIndex(finalIndex);
    };

    const setPeriodAndIndexHelper = (period: Period, index: number | undefined): void => {
        let borne;

        switch (period) {
            case 'Annees':
                borne = borneByYear;
                break;
            case 'Mois':
                borne = borneByMonth;
                break;
            case 'Jours':
                borne = borneByDay;
                break;
        }
        setIndexHelper(borne, index);
        setSelectedPeriod(period);
        setSelectedBorne(borne);
    };

    const handleBarClick = (data: any, index: number) => {
        // `data` contient les données associées à la barre cliquée
        // `index` est l'indice de la barre dans le tableau de données

        // On ne click pas sur une barre d'heure quand on affiche un jour.
        if (selectedPeriod === 'Jours') return;

        const periodData = getSelectedDatas();
        const entry = periodData?.data[index];

        if (selectedPeriod === 'Annees') {
            // searched = "month year"
            const searched = entry?.label + ' ' + periodData?.label;
            const newIndex = byMonth?.findIndex((month) => {
                const found = month.label === searched;
                return found;
            });
            setPeriodAndIndexHelper('Mois', newIndex);
            return;
        }
        if (selectedPeriod === 'Mois') {
            // searched = "month year"
            const searched = entry?.label + ' ' + periodData?.label;
            const newIndex = byDay?.findIndex((day) => day.label === searched);

            setPeriodAndIndexHelper('Jours', newIndex);
            return;
        }
    };

    // Switch to day
    const handleDay = () => {
        const targetPeriod = 'Jours';
        if (selectedPeriod === 'Mois') {
            // Je regarde un mois et j'ai cliqué sur jour.
            // je veux le premier jour de ce mois
            const data = getSelectedDatas();
            if (data && byDay) {
                const searched = '01 ' + data?.label;
                const anotherIndex = byDay.findIndex((day) => day.label === searched);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        if (selectedPeriod === 'Annees') {
            // Je regarde une année et j'ai cliqué sur jour.
            // je veux le premier jour de cette année
            const data = getSelectedDatas();
            if (data && byDay) {
                const searched = '01 janvier' + data?.label;
                const anotherIndex = byDay.findIndex((day) => day.label === searched);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        setPeriodAndIndexHelper(targetPeriod, byDay ? byDay.length - 1 : 0);
    };

    // Switch to Month
    const handleMonth = () => {
        const targetPeriod = 'Mois';

        if (selectedPeriod === 'Jours') {
            // Je regarde un jour et j'ai cliqué sur mois.
            // je veux le mois qui contient ce jour.
            const data = getSelectedDatas();
            if (data && byMonth) {
                const elements = data?.label.split(' ');
                const searched = elements[1] + ' ' + elements[2];
                const anotherIndex = byMonth.findIndex((month) => month.label === searched);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        if (selectedPeriod === 'Annees') {
            // Je regarde une année et j'ai cliqué sur mois.
            // je veux le mois de janvier de cette année.
            const data = getSelectedDatas();
            if (data && byMonth) {
                const searched = 'janvier ' + data.label;
                const anotherIndex = byMonth.findIndex((month) => month.label === searched);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }

        // en dernier recours, on choisit un index, le dernier ou le 0.
        setPeriodAndIndexHelper(targetPeriod, byMonth ? byMonth.length - 1 : 0);
    };

    // Switch to Year
    const handleYear = () => {
        const targetPeriod = 'Annees';

        if (selectedPeriod === 'Jours') {
            // Je regarde un jour et j'ai cliqué sur années.
            // je veux l'année qui contient ce jour.
            const data = getSelectedDatas();
            if (data && byYear) {
                const elements = data?.label.split(' ');
                const anotherIndex = byYear.findIndex((year) => year.label === elements[2]);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        if (selectedPeriod === 'Mois') {
            // Je regarde un mois et j'ai cliqué sur années.
            // je veux l'année qui contient ce mois.
            const data = getSelectedDatas();
            if (data && byYear) {
                const elements = data?.label.split(' ');
                const anotherIndex = byYear.findIndex((year) => year.label === elements[1]);
                if (anotherIndex !== -1) {
                    setPeriodAndIndexHelper(targetPeriod, anotherIndex);
                    return;
                }
            }
        }
        setPeriodAndIndexHelper(targetPeriod, byYear ? byYear.length - 1 : 0);
    };

    // Year / Month + 1
    const incrementGraphDataIndex = () => {
        setIndexHelper(selectedBorne!, index + 1);
    };

    // Year / Month - 1
    const decrementGraphDataIndex = () => {
        setIndexHelper(selectedBorne!, index - 1);
    };

    const getEnedisDataFromLocalStorage = ():
        | { byDay: Array<GraphData<EnedisGraphEntry>>; byMonth: Array<GraphData<EnedisGraphEntry>>; byYear: Array<GraphData<EnedisGraphEntry>> }
        | undefined => {
        const storedData = storageService.getAudit()?.step4?.enedisMesureDetaillee?.value;

        if (storedData) return storedData;

        return undefined;
    };

    /** return all data from one type (month / year) */
    const getSelectedDataSet = (): Array<GraphData<EnedisGraphEntry>> | undefined => {
        const selectedDataType: DataType = `${selectedPeriod}${selectedUnite}`;
        switch (selectedDataType) {
            case 'JourskWh':
                return byDay;
            case 'MoiskWh':
                return byMonth;
            case 'AnneeskWh':
                return byYear;
            case 'Annees€':
            case 'Mois€':
            case 'Jours€':
            default:
                return undefined;
        }
    };

    /** return one entry of data (on month, or one year) */
    const getSelectedDatas = (): GraphData<EnedisGraphEntry> | undefined => {
        let data = getSelectedDataSet();
        if (!data) return undefined;
        if (index >= data.length) return undefined;

        return data[index];
    };

    useEffect(() => {
        getEnergyPriceProjection().then((projections: Array<EnergyPriceProjection>) => {
            const newProjPP = projectionPerPeriod;
            const past = projections[0];
            if (past.type === 'electricity') {
                newProjPP.Annees.past.unitPrice = past.unitPrice;
                newProjPP.Annees.past.year = past.year;
                newProjPP.Mois.past.unitPrice = past.unitPrice;
                newProjPP.Mois.past.year = past.year;
                newProjPP.Jours.past.unitPrice = past.unitPrice;
                newProjPP.Jours.past.year = past.year;
            }
            const futur = projections[1];
            if (past.type === 'electricity') {
                newProjPP.Annees.futur.unitPrice = futur.unitPrice;
                newProjPP.Annees.futur.year = futur.year;
                newProjPP.Mois.futur.unitPrice = futur.unitPrice;
                newProjPP.Mois.futur.year = futur.year;
                newProjPP.Jours.futur.unitPrice = futur.unitPrice;
                newProjPP.Jours.futur.year = futur.year;
            }
            const audit = storageService.stepListToAuditAndClient().audit;
            const electricitePrice: EnergyPrice = getPricesWithSubscription('electricity', audit.heaterSubscriptionElectricity.value_label);
            const year = new Date().getFullYear();
            newProjPP.Annees.current.unitPrice = electricitePrice.unitPrice;
            newProjPP.Annees.current.year = year;
            newProjPP.Mois.current.unitPrice = electricitePrice.unitPrice;
            newProjPP.Mois.current.year = year;
            newProjPP.Jours.current.unitPrice = electricitePrice.unitPrice;
            newProjPP.Jours.current.year = year;

            setProjectionPerPeriod(newProjPP);
        });

        let isMounted = true;
        //const pdlNumber = enedisCalcul.HARD_CODED_POINT_ID__Ylan_1;
        const pdlNumber = storageService.checkPropertyExistThenCreateOrRender(4, 'pdlNumber', `Numéro de PDL`, storageService.PropertyType['basic']);
        const localStorageData = getEnedisDataFromLocalStorage();
        setLoader(true);
        setErrorMessage(false);

        const loadData = async () => {
            // if (
            //     localStorageData !== undefined &&
            //     localStorageData.byDay.length > 0 &&
            //     localStorageData.byMonth.length > 0 &&
            //     localStorageData.byYear.length > 0
            // ) {
            //     setByDay(localStorageData.byDay);
            //     setByMonth(localStorageData.byMonth);
            //     setByYear(localStorageData.byYear);

            //     const bby = getBorne(localStorageData.byYear);
            //     setBorneByYear(bby);
            //     setBorneByMonth(getBorne(localStorageData.byMonth));
            //     setBorneByDay(getBorne(localStorageData.byDay));
            //     setSelectedBorne(bby);

            //     // prend les dernières données glissantes.
            //     const ppp = projectionPerPeriod;
            //     ppp.Annees.conso = enedisCalcul.lastEnergy(localStorageData.byYear, 12);
            //     ppp.Mois.conso = enedisCalcul.lastEnergy(localStorageData.byMonth, 30);
            //     setProjectionPerPeriod(ppp);

            //     setLoader(false);
            // } else {
            enedisCalcul.getMesureDetaillee24M_fast(pdlNumber).then((values: enedisService.ConsultationMesuresDetailleesV3Output | undefined) => {
                if (!isMounted || values === undefined || values.grandeur === undefined || values.grandeur.points.length === 0) {
                    // afficher aucune données disponibles.
                    console.log('Aucune données disponibles');
                    setLoader(false);
                    setErrorMessage(true);
                    return;
                }

                const { byDay, byMonth, byYear } = enedisCalcul.groupMesureDetaillee(values);
                enedisCalcul.enrichDay(byDay);
                enedisCalcul.enrichMonth(byMonth);
                enedisCalcul.enrichYear(byYear);

                // prend les dernières données glissantes.
                const ppp = projectionPerPeriod;
                ppp.Annees.conso = enedisCalcul.lastEnergy(byYear, 12);
                ppp.Mois.conso = enedisCalcul.lastEnergy(byMonth, 30);
                setProjectionPerPeriod(ppp);

                setMesureDetaillee(values);
                setByYear(byYear);
                setByMonth(byMonth);
                setByDay(byDay);
                const isvalid = storageService.isAuditStepValid(4);
                storageService.setAuditValue(4, isvalid, 'enedisMesureDetaillee', { byYear, byMonth, byDay });

                //chercher les premières/dernières données non nulles.
                const bby = getBorne(byYear);
                setBorneByYear(bby);
                setBorneByMonth(getBorne(byMonth));
                setBorneByDay(getBorne(byDay));
                setSelectedBorne(bby);
            });
            // }
        };

        //enedisCalcul.getMesureDetaillee24M(pdlNumber).then((values: enedisService.ConsultationMesuresDetailleesV3Output) => {
        loadData();

        // useEffect cleanup
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loader && selectedBorne) {
            setIndexHelper(selectedBorne, borneByYear.max);
            setErrorMessage(false);
            setLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBorne]);

    const nextButtonText = (period: Period) => {
        switch (period) {
            case 'Jours':
                return 'Jour suivant';

            case 'Mois':
                return 'Mois suivant';

            case 'Annees':
                return 'Année suivante';
        }
    };

    const previousButtonText = (period: Period) => {
        switch (period) {
            case 'Jours':
                return 'Jour précédent';

            case 'Mois':
                return 'Mois précédent';

            case 'Annees':
                return 'Année précédente';
        }
    };

    return (
        <>
            {!loader && !errorMessage ? (
                <>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-6 col-md-1">
                            {selectedBorne && (
                                <button
                                    type="button"
                                    className="btn btn-graph-prev"
                                    onClick={decrementGraphDataIndex}
                                    disabled={index <= selectedBorne.min || selectedBorne.min === selectedBorne.max}
                                >
                                    <div className="triangle" data-direction="prev">
                                        <IconTriangle />
                                    </div>
                                    {previousButtonText(selectedPeriod)}
                                </button>
                            )}
                        </div>

                        <div className="col-12 col-md-10 order-md-0 order-first">
                            <div className="graph-current-title">
                                {getSelectedDatas()?.label}{' '}
                                <small>({`${(getSelectedDatas()?.total ?? 0).toLocaleString('fr-FR', { maximumFractionDigits: 3 })} ${selectedUnite}`})</small>
                            </div>

                            <ResponsiveContainer width="100%" height={340}>
                                <BarChart data={getSelectedDatas()?.data} margin={{ top: 20 }}>
                                    <YAxis label={{ value: selectedUnite, angle: -90, position: 'insideLeft', fill: '#666' }} width={100} />
                                    <XAxis dataKey="label" tick={{ fill: '#333' }} />
                                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                                    <Tooltip
                                        content={<CustomTooltip selectedUnite={selectedUnite} />}
                                        cursor={{ fill: 'rgba(225, 225, 225, 0.5)' }}
                                        wrapperStyle={{ outline: 'none' }}
                                    />
                                    <Bar dataKey="energieKWh" fill="#0063a6" radius={[60, 60, 0, 0]} maxBarSize={30} onClick={handleBarClick} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <div className="col-6 col-md-1">
                            {selectedBorne && (
                                <button
                                    type="button"
                                    className="btn btn-graph-next"
                                    onClick={incrementGraphDataIndex}
                                    disabled={index >= selectedBorne.max || selectedBorne.min === selectedBorne.max}
                                >
                                    <div className="triangle" data-direction="next">
                                        <IconTriangle />
                                    </div>
                                    {nextButtonText(selectedPeriod)}
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="header-filter">
                                <div className="btn-tabs">
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={handleDay}
                                        data-active={selectedPeriod === 'Jours'}
                                        disabled={selectedPeriod === 'Jours'}
                                    >
                                        Jours
                                    </button>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={handleMonth}
                                        data-active={selectedPeriod === 'Mois'}
                                        disabled={selectedPeriod === 'Mois'}
                                    >
                                        Mois
                                    </button>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={handleYear}
                                        data-active={selectedPeriod === 'Annees'}
                                        disabled={selectedPeriod === 'Annees'}
                                    >
                                        Années
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        {selectedPeriod && selectedPeriod !== 'Jours' && projectionPerPeriod[selectedPeriod] && (
                            <div className="col mt-4">
                                <ul className="enedis-list">
                                    <li>
                                        Votre dépense {selectedPeriod === 'Annees' ? 'annuelle' : 'mensuelle'} d’électricité au cours des{' '}
                                        {selectedPeriod === 'Annees' ? '12 derniers mois' : '30 derniers jours'}, hors abonnement, s'élève à&nbsp;
                                        <span className="font-weight-bold">
                                            {currencyFormat(projectionPerPeriod[selectedPeriod].conso * projectionPerPeriod[selectedPeriod].current.unitPrice)}
                                        </span>
                                        .
                                    </li>
                                    <li>
                                        Si le prix du kWh était resté à {projectionPerPeriod[selectedPeriod].past.unitPrice} € (prix de{' '}
                                        {projectionPerPeriod[selectedPeriod].past.year}), votre dépense aurait été de{' '}
                                        {currencyFormat(projectionPerPeriod[selectedPeriod].conso * projectionPerPeriod[selectedPeriod].past.unitPrice)}.
                                    </li>
                                    <li>
                                        <span className="font-weight-bold">
                                            Sans prise de mesure, votre facture {selectedPeriod === 'Annees' ? 'annuelle' : 'mensuelle'} d'électricité
                                            augmentera à{' '}
                                            {currencyFormat(projectionPerPeriod[selectedPeriod].conso * projectionPerPeriod[selectedPeriod].futur.unitPrice)}{' '}
                                            d'ici la fin de {projectionPerPeriod[selectedPeriod].futur.year} en raison de la fin du tarif réglementé.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </>
            ) : errorMessage ? (
                <div className="text-center">
                    <IconProhibited width={50} height={50} fill="#C4C4C4" />
                    <p className="mb-0 mt-3">
                        Nous n’avons malheureusement pas réussi à récupérer les données de consommation associées à votre PDL.
                        <br />
                        Il se peut que vous n’ayez pas accepté que votre fournisseur partage ces données.
                    </p>
                </div>
            ) : (
                <div className="text-center">
                    <LoaderSVG width={80} height={80} />
                    <p className="mb-0">
                        Mise à jour de vos données de consommation.
                        <br />
                        Cette action peut durer plusieurs secondes, merci de patienter.
                    </p>
                </div>
            )}
        </>
    );
};

export default EnedisGraphic;
