import React from 'react';
import { Back } from '../../../components/Back/Back';
import * as routes from '../../../routing/paths';

const Marketing: React.FC = () => {
    return (
        <>
            <div className="container mt-5">
                <Back title="Mon espace" url={routes.ROUTE_EA_INDEX} />
            </div>

            <iframe
                src="https://www.sogys.fr/outils-marketing-sogys"
                width="100%"
                height="100%"
                title="Outil marketing"
                className="iframe-marketing"
                allowFullScreen
            />
        </>
    );
};

export default Marketing;
