import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import * as apiParticulierService from '../services/apiParticulierService';
import * as storageService from '../services/localStorageService';
import { useHistory } from 'react-router';
import { getFlow } from '../services/apiFlowService';
import Template from '../assets/json/template-audit.json';

type NavigationContextProps = {
    validateThenRoute: (step: number, route: string) => Promise<void>;
    returnToHome: (step: number, route: string) => Promise<void>;
};

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const useStepNavigation = () => {
    const context = useContext(NavigationContext);
    if (context === undefined) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return context;
};

export const NavigationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const AlertSwal = withReactContent(Swal);
    const flowId = localStorage.getItem('flowId');
    if (!flowId) console.log('NavigationProvider flowId null');
    const { push } = useHistory();
    /**
     * Gestion désactivation formulaire
     */
    const [auditSent, setAuditSent] = useState(false);
    useEffect(() => {
        /**
         * Vérification si Audit déjà envoyé
         */
        const audit = storageService.getAudit();
        if (audit && audit.auditSent) {
            setAuditSent(true);
        }
    }, []);

    const returnToHome = async (step: number, route: string): Promise<void> => {
        console.log('StepNav returnToHome ' + JSON.stringify({ flowId, auditSent, globalCurrentStep: storageService.getAuditCurrentStep(), step }, null, 2));

        const { isConfirmed } = await AlertSwal.fire({
            title: 'Retour au menu principal',
            html: (
                <>
                    <p>En quittant cet écran, vous perdrez toutes les modifications effectuées sur cet écran.</p>
                    <p>Sélectionnez le bouton 'Etape suivante' pour les sauvegarder.</p>
                </>
            ),
            width: 550,
            confirmButtonText: 'Quitter cet écran',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            focusCancel: true,
            customClass: {
                cancelButton: 'btn btn-secondary min-width',
                confirmButton: 'btn btn-green min-width',
            },
        });

        if (isConfirmed) {
            if (!flowId) {
                if (step === 1) storageService.setAudit(Template);
                else throw new Error("Impossible d'envoyer les données de l'audit, parcours utilisateur inexistant");
            } else {
                const flowDetail = await getFlow(flowId);
                storageService.setAudit(flowDetail.audit);
            }
            AlertSwal.close();
            push(route);
        }
    };

    /**
     * Fonction permettant la mise en local storage de la validité du formulaire + redirection
     * @param step (étape)
     * @param route (destination)
     */
    const validateThenRoute = async (step: number, route: string): Promise<void> => {
        try {
            console.log(
                'StepNav validate then route ' + JSON.stringify({ flowId, auditSent, globalCurrentStep: storageService.getAuditCurrentStep(), step }, null, 2)
            );
            if (!flowId) {
                await apiParticulierService.createAuditFlow();
            }
            if (!auditSent) {
                // Mise à jour de l'audit en base
                await apiParticulierService.updateAuditDataService();
                // Mise à jour de la validité du formulaire dans le JSON pour cette étape précise
                storageService.setAuditStepValidity(step, true);
            }
            // Mise à jour de l'étape globale
            let globalCurrentStep = storageService.getAuditCurrentStep();
            if (step < 8 && step === globalCurrentStep) {
                storageService.setAuditCurrentStep(globalCurrentStep + 1);
            }
            AlertSwal.close();

            push(route);
        } catch (e) {
            console.error(e);
        }
    };
    return <NavigationContext.Provider value={{ validateThenRoute, returnToHome }}>{children}</NavigationContext.Provider>;
};
