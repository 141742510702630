import React from 'react';
import { RouteProps, Redirect, Route } from 'react-router-dom';
import PageLayout from '../layouts/pageLayout';
import Cookies from 'js-cookie';
import * as storageService from '../services/localStorageService';

import { areAllConfigFilesReady } from '../services/apiParticulierService';
import { ROUTE_AUDIT, ROUTE_LOGIN } from './paths';

interface IPrivateRoute extends RouteProps {
    providers?: any[];
    name?: string | undefined;
    step?: number | undefined;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({ component: Component, providers, ...rest }) => {
    /**
     * Vérification si utilisateur identifié avec cookie
     */
    const auth = Cookies.getJSON('Auth');
    if (!auth) {
        return <Redirect to={ROUTE_LOGIN} />;
    }

    /**
     * Vérification si les fichiers de conf sont ok. sinon, force le login qui les re-télécharge.
     */
    if (!areAllConfigFilesReady()) {
        return <Redirect to="/login" />;
    }

    /**
     * Vérification si l'étape global est inférieure à l'étape demandée sinon redirection
     */
    if (rest.step) {
        if (storageService.getAuditCurrentStep() < rest.step) {
            return <Redirect to={ROUTE_AUDIT} />;
        }
    }

    let CustomRoute = <Route component={Component} {...rest} />;
    if (providers) {
        CustomRoute = providers.reduce((acc: any, Provider: any) => {
            return <Provider>{acc}</Provider>;
        }, <Route component={Component} {...rest} />);
    }

    return <PageLayout>{CustomRoute}</PageLayout>;
};

export default PrivateRoute;
